import GenerateProfilePic from "../../components/Common/GenerateProfilePictureIntitals";
import actions from "../actions/actionTypes";

let initialState = {
  Questions: { Count: 0, Questions: [] },
  QuestionPageNumber: 2,
  loaded: false,
  questionLazyLoader: true
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_QUESTION_SUCCEEDED: {
      console.log(action);
      console.log(state);
      return {
        ...state,
        Questions: {
          Count: action.questions === "" ? 0 : action.questions.Count,
          Questions: action.questions === "" ? [] : action.questions.Questions
        },
        loaded: true,
      };
    }
    case actions.FETCH_QUESTION_LOADMORE_SUCCEEDED:
      return {
        ...state,
        Questions: { Count: action.questions.Count, Questions: [...state.Questions.Questions, ...action.questions.Questions] },
        questionLazyLoader: true,
        loaded: true,
        QuestionPageNumber: state.QuestionPageNumber + 1,
      };

    case actions.FETCH_QUESTION_LOADMORE_FINISHED:
      return {
        ...state,
        questionLazyLoader: false,
      };
    default:
      return state;
  }
}

export default questionReducer;