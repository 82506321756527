export default function download(url, name) {
    fetch(
        url,
        {
            method: "GET",
            headers: {}
        })
        .then((response) => {
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.download = name;
                link.click();
            });
        })
        .catch((err) => {
            console.log(err);
        });
    // fetch(url, {mode: 'no-cors'})
    //     .then(response => {
    //         response.blob().then(blob => {
    //             let url = window.URL.createObjectURL(blob);
    //             let a = document.createElement('a');
    //             a.href = url;
    //             a.download = name;
    //             a.click();
    //         });
    //         //window.location.href = response.url;
    // });
}
