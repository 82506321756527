import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import Cookies from "js-cookie";
import ErrorTab from "../Common/ErrorTab";
import Spinner from "../Common/Spinner";

export const TutorialList = (props) => {
  console.log(props.props)
  
  if (props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((tutorial, idx) => {
          const detailId = tutorial.Id;

          return (
            <li className="card-space" key={detailId}>
              <Link to={"/videos/" + detailId} className = {"col-11 col-xs-12"} id={"listcard" + idx}>
                <div className=" card-img">
                  <img 
                  src={tutorial.ThumbnailUrl} 
                  alt="lms-logo" />
                </div>
                <div className=" card-info lms-flex-between">
                  <div className="card-title">{tutorial.CategoryName}</div>
                  <div className="card-date">
                    {Moment(tutorial.CreatedDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="card-heading">{tutorial.Title}</div>
                <div className="card-para">{tutorial.Description}</div>
              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  }
  else{
    return <ErrorTab class={"no-result"}/>
  }
};

export default TutorialList;
