import JoditEditor from 'jodit-react';
import React, { useState, useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const MyComponent = props => {
  const editor = useRef(null);
  const [contents, setContents] = React.useState('')

  React.useEffect(() => {
   setContents(props.value)
  }, [])
  
  console.log(props.value)

  const handleContent = (data) => {
    console.log(data)
    setContents(data)
    props.content(data)
  };
 
  return (
    <div>  
        <SunEditor
          onChange={handleContent}
          setContents={contents}
      />

    </div>
  );
};
export default MyComponent;