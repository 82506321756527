import actions from "./actionTypes";

export const getAllUsers = (payload) => {
    return {
      type: actions.FETCH_ALL_USERS_START,
      payload
    };
  };

  export const loadMoreUsers = (payload) => {
    return {
      type: actions.FETCH_USERS_LOADMORE_START,
      payload
    };
  };
