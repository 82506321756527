
import { Typography } from "@mui/material";
import React from "react";
import { connect, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { courseContentTypes, toTime } from "../Common/Constants";


export default function Playlist(props) {
  const params = useParams()

  return (
    <div>
      <Typography className="play-list-tab">PlayList</Typography>
      {
        props.playlist.map((video, idx) => {
          return (
            <Link to={"/course/" + props.id + "/" + idx}>
              <div className={params.videoId == idx && `playlist_videos   ${document.body.dir == "ltr" ? "left-border-play-list" : "right-border-play-list"}`}>
                <li className="playlistvideo">
                  <div className="lms-flex-align-center playlist-flex">
                    <div className="lms-playlist-video">
                      <div className="lms-video-image">
                        {
                          video.Type === courseContentTypes.Quiz &&
                          <img className="playlist-quiz-img"
                            src="../assets/document-logo.png"
                            alt="quiz-img"
                          ></img>
                        }
                        {
                          video.Type === courseContentTypes.Video &&
                          <img
                            src={video.ThumbnailUrl}
                            alt="playlist-video-img"
                          ></img>
                        }
                        {
                          video.Type === courseContentTypes.Video &&
                          <div className={`video-duration-cont ${document.body.dir == "ltr" ? "ltr-video-duration" : "rtl-video-duration"}`}>
                            <p className="video-duration">{toTime(video.VideoHistory == null ? 0 : video.VideoHistory.SeekTime)}</p>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="lms-playlist-content">
                      <div className="lms-playlistvideo-content">
                        <div className="playlist-heading">{video.Title}</div>
                        <p className="playlist-para">{video.Description} </p>
                      </div>
                    </div>
                    <div className="lms-playicon">
                      <div className="lms-play">
                        {params.videoId == idx ?
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="15.744" className="svg-stroke" stroke-width="0.512" />
                            <rect x="12" y="10" width="3" height="12" className="svg-for-menu" />
                            <rect x="17" y="10" width="3" height="12" className="svg-for-menu" />
                          </svg> :
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.0095 15.2432C18.9377 13.4829 15.8557 11.7365 12.7634 10.004C11.9924 9.56791 11.378 9.87347 11.378 10.7249C11.3473 14.2335 11.3473 17.7421 11.378 21.2507C11.378 22.108 11.9924 22.4374 12.7603 22.0012C15.8772 20.2608 18.9817 18.5006 22.074 16.7206C22.787 16.265 22.4829 15.4838 22.0095 15.2432Z" className="svg-for-menu" />
                            <circle cx="16" cy="16" r="15.744" className="svg-stroke" stroke-width="0.512" />
                          </svg>}
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </Link>
          )
        })
      }
    </div>
  )
}