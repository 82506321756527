
import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserDashboard from "./UserDashboard";
import CertificatesList from "./CertificatesList";
import EditUserProfile from "./EditUserProfile"
import SortByLabel from "../../components/TranslatedComponents/SortByLabel";
import SortLabel from "../../components/TranslatedComponents/SortLabel";
import CategoryLabel from "../../components/TranslatedComponents/CategoryLabel";

import i18n from "../../utils/i18n";
import UserCoursesDetails from "./UserCoursesDetails";
import { connect } from "react-redux";
import {
  fetchBadges,
  fetchCertificates,
  fetchCompletedCourses,
  fetchInprogressCourses
} from "../../store/actions/courses";
import { bindActionCreators } from "redux";
import Analytics from "./Analytics";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index * 2}
      id={`simple-tabpanel-${index * 2}`}
      aria-labelledby={`simple-tab-${index * 2}`}
      {...other}
    >
      {value === index * 2 && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index * 2}`,
    "aria-controls": `simple-tabpanel-${index * 2}`,
  };
}

export class ProfileTabs extends Component {
  state = { value: 0, displayEditTab: false, };
  handleEditBtn = () => { this.setState({ displayEditTab: !this.state.displayEditTab }) }
  constructor(props) {
    super(props);}
    componentDidMount() {
      this.setState({ value: this.props.index * 2 })
      const ext = location.pathname.split('/').pop()
      console.log('link',ext)
      if(ext === 'userinfo')
      {
        this.setState({ value: 0 })
      }
      if(ext === 'certificates')
      {
        this.setState({ value: 2 })
      }
      if(ext === 'analytics')
      {
        this.setState({ value: 4 })
      }
      const { fetchInprogressCoursesAction, fetchCompletedCoursesAction, fetchCertificatesActions, fetchBadgesAction } = this.props;
      fetchInprogressCoursesAction();
      fetchCompletedCoursesAction();
      fetchCertificatesActions();
      fetchBadgesAction()
    }
    
  render() {
    const { t } = this.props;
    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
      this.props.navigate(newValue)
      window.scrollTo(0,0)
    };
    const isRightToLeft = document.body.dir === 'rtl';
    return (
      <Box sx={{ width: "100%", marginTop: "6.8rem" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className={"col-tab pro-tabs"}>
          <Tabs
            value={this.state.value}
            className={"collection-bg"}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ display: "block", verticalAlign: "middle" }}
              className={`profile-tabs ${isRightToLeft ? 'profi-right-mar' : 'profi-left-mar'}`}
              label={i18n.t("My_Profile_Label")}
              {...a11yProps(0)}
            />
            <div className="border-for-collection-tabs"></div>
            <Tab

              style={{ display: "block", verticalAlign: "middle" }}
              label={i18n.t("Certifications_label")}
              className={`profile-tabs ${isRightToLeft ? 'profi-right-mar' : 'profi-left-mar'}`}
              {...a11yProps(1)}
            />
            <div className="border-for-collection-tabs"></div>
            <Tab

              style={{ display: "block", verticalAlign: "middle" }}
              className={`profile-tabs ${isRightToLeft ? 'profi-right-mar' : 'profi-left-mar'}`}
              label={i18n.t("Analytics_Label")}
              {...a11yProps(2)}
            />
            <div className="border-for-collection-tabs"></div>

          </Tabs>
          {this.state.value === 0 && !this.state.displayEditTab &&
            <div className={`d-flex pro-tbs-sort ${isRightToLeft ? "profile-tbs-r-sort" : "profile-tbs-l-sort"}`}>
              <div className="border-for-collection-tabs" style={{ margin: "0" }}></div>
              <div className="right-icon profile-tbs lms-flex-align-center dropdown" style={{ opacity: 1 }}>
                <svg
                  className="col-5"
                  width="20px"
                  height="14px"
                  viewBox="0 0 20 14"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Visual-Design-2"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Dashboard"
                      transform="translate(-1715.000000, -328.000000)"
                      className="svg-for-menu"
                      fillRule="nonzero"
                    >
                      <g
                        id="Search-&amp;-filters-2"
                        transform="translate(195.000000, 298.000000)"
                      >
                        <g
                          id="Group"
                          transform="translate(1520.000000, 28.000000)"
                        >
                          <g
                            id="noun_Sort_2005356"
                            transform="translate(0.000000, 2.000000)"
                          >
                            <path
                              d="M5.71428571,11.2 C6.50326393,11.2 7.14285714,11.8268014 7.14285714,12.6 C7.14285714,13.3731986 6.50326393,14 5.71428571,14 L1.42857143,14 C0.639593215,14 0,13.3731986 0,12.6 C0,11.8268014 0.639593215,11.2 1.42857143,11.2 L5.71428571,11.2 Z M14.2857143,5.6 C15.0746925,5.6 15.7142857,6.22680135 15.7142857,7 C15.7142857,7.77319865 15.0746925,8.4 14.2857143,8.4 L1.42857143,8.4 C0.639593215,8.4 0,7.77319865 0,7 C0,6.22680135 0.639593215,5.6 1.42857143,5.6 L14.2857143,5.6 Z M18.5714286,0 C19.3604068,0 20,0.62680135 20,1.4 C20,2.17319865 19.3604068,2.8 18.5714286,2.8 L1.42857143,2.8 C0.639593215,2.8 0,2.17319865 0,1.4 C0,0.62680135 0.639593215,0 1.42857143,0 L18.5714286,0 Z"
                              id="Combined-Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <p className="profile-tbs-label"><SortLabel /></p>
                <div className={`dropdown-content ${document.body.dir == 'rtl' ? 'rtl-drop-down' : 'ltr-drop-down'}`}>
                  <div className="form-field">
                    <label htmlFor="sort"><SortByLabel /></label>
                    <select id="filter">
                      <option>Ascending</option>
                      <option>Decending</option>
                    </select>
                  </div>
                  <div className="form-field">
                    <label htmlFor="sort"><CategoryLabel /></label>
                    <select id="filter">
                      <option>Leadership</option>
                      <option>Technology</option>
                      <option>Soft skills</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          }
        </Box>


        <TabPanel value={this.state.value} index={0}>
          {!this.state.displayEditTab && <UserDashboard props={this.props} handleEditBtn={this.handleEditBtn}></UserDashboard>}
          {!this.state.displayEditTab && <UserCoursesDetails props={this.props}></UserCoursesDetails>}
          {this.state.displayEditTab && <EditUserProfile props={this.props} handleEditBtn={this.handleEditBtn}></EditUserProfile>}
        </TabPanel>

        <TabPanel value={this.state.value} index={1}>
          <div className="user-cerficates-con lms-flex">
            <div className="user-certificates">
              <div className="heading">
                <h6>{t("All_Certificates")}</h6>
                <div className="certificates-list">
                  <ul className="certi-list lms-flex">
                    <CertificatesList certificates={this.props.certificates} users={this.props.users} ></CertificatesList>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="badges-con">
              <div className="heading">
                <h6>Badges Achieved</h6>
              </div>
              <div className="badges-list-con">
                <ul className="badges-list">
                  <li>
                    <div className="level lms-flex-align-center">
                      <div className="badge-logo"><img src="assets/police-badge.png" alt="Badge" className="badges-img" /></div>
                      <div className="badge-name">Level 1</div>
                    </div>
                  </li>
                  <li>
                    <div className="level lms-flex-align-center">
                      <div className="badge-logo"><img src="assets/badge.png" alt="Badge" className="badges-img" /></div>
                      <div className="badge-name">Level 2</div>
                    </div>
                  </li>
                  <li>
                    <div className="level lms-flex-align-center">
                      <div className="badge-logo"><img src="assets/police-badge-black.png" alt="Badge" className="badges-img" /></div>
                      <div className="badge-name">Level 3</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </TabPanel>

        <TabPanel value={this.state.value} index={2}>

          {/* <div id="no-result-saved-item">
            <h1>Coming soon.</h1>
            <p>You can see your progress and analytics here.</p>
          </div> */}
          <Analytics />
          {/* <section id="colle-grid">
          <Searchfilter />
          <Datagriddata />
        </section> */}
        </TabPanel>
      </Box>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
console.log(state)
  return {
    profile: state.profileData,
    userClaims: state.user,
    users: state.allusers.allUsers,
    InprogressCourses: state.courses.inprogressCourses,
    CompletedCourses: state.courses.completedCourses,
    certificates: state.courses.certificates,
    badges: state.courses.badges,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInprogressCoursesAction: fetchInprogressCourses,
      fetchCompletedCoursesAction: fetchCompletedCourses,
      fetchCertificatesActions: fetchCertificates,
      fetchBadgesAction: fetchBadges,
    },
    dispatch
  );
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProfileTabs));
