import actionTypes from "./actionTypes";

export const fetchCategories = () => {
    return {
      type: actionTypes.FETCH_CATEGORY_START,
    };
  };
  
  export const fetchPlaylistCategories = () => {
    return {
      type: actionTypes.FETCH_PLAYLIST_CATEGORY_START,
    };
  };