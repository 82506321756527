import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import tutorialsReducer from "./reducers/tutorials";
import coursesReducer from "./reducers/courses";
import errorsReducer from "./reducers/errors";
import userReducer from "./reducers/userReducer";
import storageSession from 'redux-persist/lib/storage/session'


import { rootSaga } from "./sagas/index";
import { persistReducer } from 'redux-persist'
import profileDataReducer from "./reducers/profileDataReducer";
import categoryReducer from "./reducers/categoryReducer";
import allUserReducer from "./reducers/AllUsersReducers.js";
import roleReducer from "./reducers/roleReducers";
import storage from "redux-persist/lib/storage";
import BlueBarReducer from "./reducers/BlueBarReducer";
import OrganisationReducer from "./reducers/OrganisationReducer";
import quizReducer from "./reducers/QuizReducer";
import TenantReducer from "./reducers/TenantReducer";
import actionTypes from "./actions/actionTypes";
import allGroupsReducer from "./reducers/groupReducer.js";
import questionReducer from "./reducers/QuestionReducers.js";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();



const reducers = combineReducers({
  tutorials: tutorialsReducer,
  courses: coursesReducer,
  error: errorsReducer,
  user: userReducer,
  profileData: profileDataReducer,
  category: categoryReducer,
  allusers: allUserReducer,
  roles: roleReducer,
  bluebar: BlueBarReducer,
  organisation: OrganisationReducer,
  quiz: quizReducer,
  tenant: TenantReducer,
  groups: allGroupsReducer,
  questions: questionReducer
})

const rootReducer = (state, action) => {
  console.log('configureStore', state)
  if (action.type === actionTypes.SIGNOUT_USER) {
    console.log('configureStoreIn', state)
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root')
    // storage.removeItem('persist:otherKey')
    console.log('configureStoreInAfterRemove', state)

    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
  // blacklist: ['user']

};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga);



export default store;