AFRAME.registerComponent('play-on-click', {
    init: function () {
      // this.onClick = this.onClick.bind(this);
      // console.log(this.onClick)
      var videoEl = this.el.getAttribute('material').src;
      var video = document.getElementById("video")
      var videosphere = document.getElementById("vidEl")
      var playButton = document.getElementById("vr-playbtn")
      var currentTimeText = document.getElementById('vr-seektime');
      var videoTime = document.getElementById('vr-videotime');
  
      const forward = document.getElementById('vr-for-ten');
      const backward = document.getElementById('vr-back-ten');
      const seekbar = document.getElementById('vr-seekbar');
  
  
  
  
  
  
      const toTime = (seconds) => {
        var date = new Date(null);
        date.setSeconds(seconds);
        return date.toISOString().substr(11, 8);
      }
  
  
      if (!videoEl) { return; }
  
      console.log(video)
      videoEl.addEventListener('loadedmetadata', async (evt) => {
        var mediaLen = evt.srcElement.duration
  
        playButton.addEventListener('click', async (evt) => {
          try {
            if (videosphere.className === 'playing') {
              await videoEl.pause();
              this.el.object3D.visible = true;
              videosphere.className = "";
              playButton.setAttribute("src", "assets/playbtn.png")
            }
            else {
              await videoEl.play();
              videosphere.className = "playing";
              this.el.object3D.visible = true;
              playButton.setAttribute("src", "assets/pause.png")
            }
          }
          catch (err) {
            videosphere.className = "";
          }
  
        })
  
        // videoTime.textContent = toTime()
  
        videoEl.addEventListener('timeupdate', (evt) => {
          var value = (100 / mediaLen) * evt.srcElement.currentTime;
          console.log(value)
          currentTimeText.textContent = toTime(evt.srcElement.currentTime)
  
          seekbar.value = value
        });
  
        forward.addEventListener('click', async (evt) => {
          videoEl.currentTime = videoEl.currentTime + 10
          await videoEl.play();
        });
  
        backward.addEventListener('click', async (evt) => {
          videoEl.currentTime = videoEl.currentTime - 10
          await videoEl.play();
        });
  
  
        videoEl.addEventListener('ended', async (evt) => {
          await videoEl.pause();
          this.el.object3D.visible = true;
          videosphere.className = "";
          playButton.setAttribute("src", "assets/playbtn.png")
        });
      })
  
  
      // if (!videoEl) { return; }
      // this.el.object3D.visible = true;
      // videoEl.play();
    }
  });
  