import React from 'react'
import SideNav from './SideNav'
import { Outlet } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import store from '../../store/configureStore'
import { RotatingLines } from 'react-loader-spinner'

export default function LoadingScreen() {
    const orgLogo = useSelector(() => store.getState().organisation.organisation.OrganizationLogoUrl)

    return (
        <div className='loader'>
            <div className="container-fluid">
                <div className='react-loader' >
                    <RotatingLines
                        strokeColor='#5681ef'
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="75"
                        visible={true}
                    />
                </div>
            </div>
        </div>
    )
}
