import { call, put } from "redux-saga/effects";
import API from "../../utils/API";
import actions from "../actions/actionTypes";

export function* fetchCategoriesSaga() {
    try {
      const response = yield call(API.fetchCategories);
      const category = yield response.data;
      console.log(category)
  
      yield put({
        type: actions.FETCH_CATEGORY_SUCCEEDED,
        payload: category,
      });
    } catch (error) {
      console.log(error)
      yield put({
        type: actions.FETCH_CATEGORY_FAILED,
        message: error.message
      });
    }
  }
  
  export function* fetchPlaylistCategoriesSaga() {
    try {
      const response = yield call(API.fetchPlaylistCategories);
      const category = yield response.data;
      console.log(category)
  
      yield put({
        type: actions.FETCH_PLAYLIST_CATEGORY_SUCCEEDED,
        payload: category,
      });
    } catch (error) {
      console.log(error)
      yield put({
        type: actions.FETCH_PLAYLIST_CATEGORY_FAILED,
        message: error.message
      });
    }
  }