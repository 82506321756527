import React from "react";

export default function PageNotFound(){
    const [data,setData] = React.useState([]);

    return (
        <section className="page-not-found-con">
            <div className="page-not-left-con">
                <img src = "assets/page-not-found/page-not-found.jpg" />
            </div>
            <div className="page-not-right-con">
                <h1>401</h1>
                <h2>We are Sorry...</h2>
                <p>The page you are trying to access has restricted access. Please refer to your administrator</p>
            </div>
        </section>
    )
}