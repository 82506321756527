import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ErrorTab(props) {

    const { t } = useTranslation()
    return (
        <div id={props.class}>
            <h1>{t("Error_Heading")}</h1>
            <p>{t("Error_Body")}</p>
        </div>
    )
}
