import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Videoslider from "./Videoslider";
import Detailtabs from "./Detailtabs";
import { fetchSavedTutorials, fetchTutorialsDetail, resetTutorialDetails } from "../../store/actions/tutorials";
import Moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import PlaceholderLoader from "../Common/PlaceholderLoader";
import SimilarVideosLabel from "../TranslatedComponents/SimilarVideosLabel"
import API from "../../utils/API";
import store from "../../store/configureStore";
import { Alert, Backdrop, IconButton, List, ListItem, ListItemText, Popover, Slider } from "@mui/material";
import { getAllUsers } from "../../store/actions/AllUsersAction";
import ReactPlayer from "react-player";
import GenerateProfilePic from "../Common/GenerateProfilePictureIntitals";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import VideoLoadScreen from "../Common/VideoLoadScreen";
import LoadingScreen from "../Common/LoadingScreen";
import { useTranslation } from "react-i18next";
import { toTime } from "../Common/Constants";
import '../../script'


const TutorialDetail = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [shareVideo, setShareVideo] = React.useState([]);
  const [suggestions, setSuggestions] = React.useState([]);
  const [suggestionIndex, setSuggestionIndex] = React.useState(null);
  const [suggestionsActive, setSuggestionsActive] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState('')
  const [alert, setAlert] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState('');
  const [backdropOpen, setbackDropOpen] = React.useState(false);
  const [alertData, setAlertData] = React.useState('');
  const playerRef = React.useRef(null);
  const maxAllowedTime = 0;

  const { t } = useTranslation();

  const Video_Bookmarked = t("Video_Bookmarked")
  const Video_Removed_From_Bookmarked = t("Video_Removed_From_Bookmarked")
  const Video_Shared = t("Video_Shared")
  const Video_unShared = t("Video_unShared")
  const Video_Copy_to_ClipBoard = t("Video_Copy_to_ClipBoard")
  const Enroll = t("Enroll")
  const Enrolled = t("Enrolled")
  const Share_Video_with = t("Share_Video_with")
  const Share_with = t("Share_with")
  const Copy_Shareable_Link = t("Copy_Shareable_Link")
  const Search_PlaceHolder_Label = t("Search_PlaceHolder_Label")
  const No_Suggetions = t("No_Suggetions")



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearch('')
    setSuggestions([])
    setSuggestionIndex(null)
    setSuggestionsActive(false)
  };

  const handleBackDropClose = () => {
    setbackDropOpen(false);
    setAlertData('')
  };
  const handleBackDropOpen = (data) => {
    setbackDropOpen(data);
  };

  useEffect(() => {
    setTimeout(() => {
      handleBackDropClose();
    }, 4000);
  }, [backdropOpen]);

  const { id } = useParams();
  const userId = useSelector(() => store.getState().profileData.id);

  const { fetchTutorialsDetail, tutorialsDetail, loaded, fetchUserAction, tutorialsDetailLoaded } = props;
  useEffect(() => {
    fetchTutorialsDetail(id);
    fetchUserAction({
      PageNumber: 1
    })
    API.fetchShareVideoById({
      userId: userId,
      videoId: id
    }).then((res) => setShareVideo(res.data))
  }, [fetchTutorialsDetail, id]);

  useEffect(() => {
    if (tutorialsDetail) {
      setIsSaved(tutorialsDetail.IsVidoeSaved)
    }

  }, [tutorialsDetail, id]);
  // React.useEffect(()=>{
  //   return () => {
  //     resetTutorialDetails()
  //   }
  // },[id != tutorialsDetail.Id])


  const handleSaveAndUnSave = async (id) => {
    isSaved ?
      await API.unSaveTutorials({ userId: userId, videoId: id }).then(res => res.status === 200 && (
        setIsSaved(prevState => !prevState)),
        handleSuccess({
          body: Video_Removed_From_Bookmarked,
          isTrue: true
        })
      ) :
      await API.saveTutorials({ userId: userId, videoId: id }).then(res => res.status === 201 && (setIsSaved(prevState => !prevState), handleSuccess({
        body: Video_Bookmarked,
        isTrue: true
      })))
    // fetchTutorialsDetail(id)
  }

  const handleUnShareVideos = async (shareWith) => {
    await API.unShareVideoAPI({ shareBy: userId, videoId: id, shareWith: shareWith }).then(res => res.status === 200 && handleSuccess({
      body: Video_unShared,
      isTrue: true
    }))
    fetchUserAction({
      PageNumber: 1
    })
    await API.fetchShareVideoById({
      userId: userId,
      videoId: id
    }).then((res) => setShareVideo(res.data))
    console.log('unshare')

  }

  const handleShareVideos = async () => {
    await API.shareVideoAPI({ shareBy: userId, videoId: id, shareWith: selectedUser }).then(res => res.status === 201 && handleSuccess({
      body: Video_Shared,
      isTrue: true
    }))
    fetchUserAction({
      PageNumber: 1
    })
    await API.fetchShareVideoById({
      userId: userId,
      videoId: id
    }).then((res) =>
      setShareVideo(res.data),
      setSuggestionsActive(false),
      setSearch('')
    )
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleClickSuggetion = (e, i) => {
    setSelectedUser(e)
    setSuggestionIndex(i)
  };

  const Suggestions = () => {
    return (
      <>
        {suggestions.length > 0 ? <> <ul className="suggestions_ul">
          {suggestions.filter(i => i.Id != userId && !suggestions.includes(shareVideo)).map((suggestion, index) => {
            return (
              <ListItem
                key={suggestion.Id}
                disableGutters
                className={index === suggestionIndex ? "suggestions_active" : "suggestions"}
                onClick={() => {
                  handleClickSuggetion(suggestion.Id, index)

                }}
              >
                <div className={`user-inf lms-flex`}>
                  <div className="col-2 share-user">
                    {
                      suggestion.ProfileImageUrl === '' || suggestion.ProfileImageUrl === null ?
                        <img src={GenerateProfilePic(suggestion.Name)} className='profile-avatar' />
                        :
                        <img src={suggestion.ProfileImageUrl} className='profile-avatar' />
                    }

                  </div>
                  <div className="share-user-inf">
                    <ListItemText className="list-text" primary={`${suggestion.Name}`} />
                    <p className="email">{suggestion.Email}</p>
                  </div>
                </div>
              </ListItem>
              // <li
              //   className={index === suggestionIndex ? "suggestions_active" : "suggestions"}
              //   key={index}
              //   onClick={() => handleClickSuggetion(suggestion.Id, index)}
              // >
              //   <spna>
              //     {suggestion.Name}
              //   </spna>
              // </li>
            );
          })}
        </ul>
          <button className="primary-button share-con-btn mb-3" onClick={handleShareVideos}>Share</button>
        </> :
          <h3 className="no-suggestions">{No_Suggetions}</h3>}

      </>
    );
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      search.length > 0 ?
        API.fetchUsers({
          filterField: 'Name',
          FilterText: search
        }).then(res => {
          setSuggestions(res.data.Users.filter(a => a.IsActive && !a.Id.includes(userId)))
          setSuggestionsActive(true);
        })
        :
        setSuggestionsActive(false)

    }, 500);
    return () => clearTimeout(timer)
  }, [search])

  const open = Boolean(anchorEl);
  const popId = open ? 'simple-popover' : undefined;
  const handleEndCall = (e) => {
    API.postVideoSeekTime({ userId: userId, videoId: tutorialsDetail.Id, seekTime: playerRef.current?.getCurrentTime(), videoLength: playerRef.current?.getDuration() })
  }

  const handleSuccess = (data) => {
    setAlertData(data.body)
    handleBackDropOpen(data.isTrue)
  }

  const clearSearch = () => {
    setSuggestionsActive(false)
    setSuggestions([])
    setSearch('')
  }

  React.useEffect(() => {
    setCurrentPath(window.location.href)
    const timer = setInterval(() => {
      if (!tutorialsDetail.IsVrEnabled) {
        playerRef.current?.getDuration() != playerRef.current?.getCurrentTime() &&
          API.updateSaveVideoWatchDurationStatus({ Id: tutorialsDetail.Id, VideoLength: playerRef.current?.getDuration(), WatchedDuration: playerRef.current?.getCurrentTime() })
        API.updateSharedVideoWatchDurationStatus({ Id: tutorialsDetail.Id, VideoLength: playerRef.current?.getDuration(), WatchedDuration: playerRef.current?.getCurrentTime() })
        API.postVideoSeekTime({ userId: userId, videoId: tutorialsDetail.Id, seekTime: playerRef.current?.getCurrentTime(), videoLength: playerRef.current?.getDuration() })
      }
    }, 20000);
    return () => clearInterval(timer)
  }, [playerRef.current])


  function copyToClip() {
    navigator.clipboard.writeText(window.location.href).
      then(res => navigator.clipboard.readText().
        then(text => text.length > 0 && handleSuccess({
          body: Video_Copy_to_ClipBoard,
          isTrue: true
        })));
  }


  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const handleContextMenuCapture = (e) => {
    e.preventDefault();
  };

  const handleSeek = (e) => {
    if (e > maxAllowedTime) {
      playerRef.current.seekTo(maxAllowedTime, 'seconds');
    }
  };


  if (!tutorialsDetailLoaded) {
    return <LoadingScreen />
  }





  let lms_video_condition_classname = tutorialsDetail.IsVrEnabled ? "lms-video-vr-player" : "";

  return tutorialsDetailLoaded &&
    tutorialsDetail && (
      <section id="video-detail-sec">

        <div className="lms-flex video-detail-wrap">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdropOpen}
            onClick={handleBackDropClose}>
            <Alert severity="success" style={{ position: "absolute", top: 20 }}>
              {alertData}
            </Alert>
          </Backdrop>
          <div className="lms-w1">
            <div className={`lms-video ${lms_video_condition_classname}`}>
              {
                tutorialsDetail.IsVrEnabled ?
                  <div id="container">

                    <a-scene background="color: #ECECEC" embedded="" inspector="" keyboard-shortcuts screenshot="" vr-mode-ui="" device-orientation-permission-ui="">
                      <a-assets>
                        <video id="video" crossorigin="*" src={tutorialsDetail.CdnVideoUrl}
                          playsinline webkit-playsinline
                          style={{ display: "none" }}
                        ></video>
                      </a-assets>
                      <a-videosphere
                        rotation="0 -90 0" id="vidEl" src="#video"
                        play-on-click>
                      </a-videosphere>
                      <a-camera>
                      </a-camera>
                    </a-scene>

                    <div id="overlay-div">
                      <div className="vr-left-section">
                        <img src="assets/playbtn.png" id="vr-playbtn"></img>
                        <div className="vr-seekbar-div">
                          <div className="vr-seektime" id="vr-seektime">00:00:00</div>
                          <input className="vr-seekbar" step='any' type="range" value="0" min="0" max="100" id="vr-seekbar" />
                          <div className="vr-videotime" id="vr-videotime">{toTime(tutorialsDetail.VideoLength)} </div>
                        </div>
                      </div>
                      <div className="vr-right-section">
                        <img src="assets/ten_back.png" id="vr-back-ten"></img>
                        <img src="assets/ten_for.png" id="vr-for-ten"></img>
                      </div>
                    </div>
                  </div>
                  :
                  < ReactPlayer
                    url={tutorialsDetail.CdnVideoUrl}
                    playing
                    ref={playerRef}
                    controls
                    // onProgress={(e) => handleProgress(e)}
                    onContextMenu={handleContextMenu}
                    onContextMenuCapture={handleContextMenuCapture}
                    onSeek={(e) => handleSeek(e)}
                    onStart={() => playerRef.current?.seekTo(parseFloat(tutorialsDetail.VideoHistory == null ? 0 : tutorialsDetail.VideoHistory.SeekTime), 'seconds')}
                    width='100%'
                    height='100%'
                    onEnded={(state) =>
                      handleEndCall(state)}
                  />
              }



            </div>
            <div className="card-heading vd-heading" id="card-headingspace">
              <SimilarVideosLabel />
            </div>

            <div className="lms-first-slider">
              <Videoslider props={tutorialsDetail.RelatedVideos} />
            </div>
          </div>
          <div className="lms-w2">
            <div className="card-title card-title-w mb-md-2">
              {tutorialsDetail.CategoryName}
            </div>
            <div className="sharesave-sec">
              <div className="responsive-share-save">
                <div className="col-10 lms-texticon-w1">
                  <div className="prof-heading lms-profheading">
                    {tutorialsDetail.Title}
                  </div>
                  <div className="card-date lms-carddate">
                    {Moment(tutorialsDetail.CreatedDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="col-2 d-flex flex-column justify-content-end lms-texticon-w2">
                  <div className="share-save-icon">
                    {isSaved ?
                      <button onClick={() => { handleSaveAndUnSave(id) }} className="save-btn-new-saved" >
                        <svg className="new-save-svg"
                          width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.3589 1H1.38443C1.17184 1 1 1.15847 1 1.35374V17.6486C1 17.7905 1.09188 17.9185 1.23373 17.9741C1.37559 18.0296 1.54012 18.0017 1.65084 17.9033L7.87169 12.3914L14.0925 17.9033C14.1652 17.968 14.2613 18.0024 14.3589 18.0024C14.4097 18.0024 14.4612 17.9932 14.5096 17.9741C14.6515 17.9185 14.7434 17.7901 14.7434 17.6486V1.35374C14.7434 1.15847 14.5711 1 14.3589 1Z"
                            className="svg-for-menu-saved" stroke="white" stroke-width="0.594"
                          />
                        </svg>
                        <span>{Enrolled}</span>
                      </button>
                      :
                      <button onClick={() => { handleSaveAndUnSave(id) }} className="save-btn-new">
                        <svg className="new-save-svg"
                          width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.3589 1H1.38443C1.17184 1 1 1.15847 1 1.35374V17.6486C1 17.7905 1.09188 17.9185 1.23373 17.9741C1.37559 18.0296 1.54012 18.0017 1.65084 17.9033L7.87169 12.3914L14.0925 17.9033C14.1652 17.968 14.2613 18.0024 14.3589 18.0024C14.4097 18.0024 14.4612 17.9932 14.5096 17.9741C14.6515 17.9185 14.7434 17.7901 14.7434 17.6486V1.35374C14.7434 1.15847 14.5711 1 14.3589 1Z"
                            style={{ fill: "var(--pri-font-color)" }} stroke="white" stroke-width="0.594"
                          />
                        </svg>
                        <span>{Enroll}</span>
                      </button>
                    }
                    <svg
                      className="share-icon"
                      width="43px"
                      height="43px"
                      viewBox="0 0 43 43"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={handleClick}
                    >
                      <g
                        id="FINAL"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Video-detail_desc"
                          transform="translate(-1817.000000, -201.000000)"
                        >
                          <g
                            id="desc"
                            transform="translate(1181.500000, 118.000000)"
                          >
                            <g
                              id="CTA"
                              transform="translate(567.500000, 83.000000)"
                            >
                              <g
                                id="Group-9"
                                transform="translate(68.000000, 0.000000)"
                              >
                                <rect
                                  id="Rectangle"
                                  style={{ stroke: "var(--pri-font-color)" }}
                                  x="0.5"
                                  y="0.5"
                                  width="42"
                                  height="42"
                                  rx="8"
                                ></rect>
                                <path
                                  d="M30.1033525,21.4966401 L22.3060919,13 L22.3060919,18.0896043 L21.4003232,18.0896043 C19.1565908,18.0896043 17.0470463,18.9579347 15.4604172,20.5346536 C13.8737882,22.1113724 13,24.2077404 13,26.437588 L13,30.0023605 L14.4964308,28.3729763 C16.4981549,26.193589 19.3395981,24.9328625 22.3060919,24.9041948 L22.3060919,29.99341 L30.1033525,21.4966401 Z"
                                  id="Shape"
                                  style={{ fill: "var(--pri-font-color)" }}
                                  fill-rule="nonzero"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <Popover
                      id={popId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      PaperProps={{
                        className: `popover-width ${document.body.dir == "ltr" ? 'en-popup' : 'ar-popup'}`
                      }}
                    >
                      <div className="share-container">
                        <div className="share-con ">
                          <div >
                            {
                              suggestionsActive &&
                              <span className="view-heading-backarrow" onClick={clearSearch}>{"<"}</span>
                            }
                            {" "}
                            <span className={`${suggestionsActive ? 'share-course-title share-course-title-pad' : 'share-course-title'}`}>{Share_Video_with}</span>
                          </div>
                          <div className="share-con-input">
                            <input type="text" placeholder={Search_PlaceHolder_Label} value={search} onChange={(e) => handleSearch(e)}></input>
                          </div>
                          {suggestionsActive ? "" :
                            <div className="share-flex-con mt-3">
                              <div className="share-icons-con">
                                <FacebookShareButton url={currentPath}>
                                  <FacebookIcon size={36} borderRadius={10} />
                                </FacebookShareButton>
                                <WhatsappShareButton url={currentPath}>
                                  <WhatsappIcon size={36} borderRadius={10} />
                                </WhatsappShareButton>
                                <LinkedinShareButton url={currentPath}>
                                  <LinkedinIcon size={36} borderRadius={10} />
                                </LinkedinShareButton>
                                <TwitterShareButton url={currentPath}>
                                  <TwitterIcon size={36} borderRadius={10} />
                                </TwitterShareButton>
                              </div>
                              <div className="sharebtn-con"><button className="primary-button share-con-btn" onClick={copyToClip}>{Copy_Shareable_Link}</button></div>
                            </div>
                          }
                          <div className="search-box">
                            {suggestionsActive ? <Suggestions />
                              :
                              <>
                                <p className="share-with-span">
                                  {Share_with}
                                </p>
                                {shareVideo.length > 0 && <List className="share-con-ul" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                  {shareVideo.map((i) =>
                                  (
                                    <ListItem
                                      key={i.Id}
                                      style={{ justifyContent: "space-between" }}
                                      disableGutters
                                      secondaryAction={
                                        <IconButton aria-label="comment" className={`${document.body.dir === "ltr" ? 'ltr-position' : 'rtl-position'}`} >
                                          <button className="share-btn" onClick={() => { handleUnShareVideos(i.SharedWith) }}><img src="assets/unshare-icon.png" className="remove-icon" /></button>
                                        </IconButton>
                                        // :
                                        // <IconButton aria-label="comment">
                                        //   <button className="share-btn" ><img src="assets/share-icon.png" className="add-icon" /></button>
                                        // </IconButton>
                                      }

                                    ><div className="user-inf lms-flex">
                                        <div className="share-user">
                                          {
                                            i.SharedWithProfileImageUrl === '' || i.SharedWithProfileImageUrl === null ?
                                              <img src={GenerateProfilePic(i.SharedWithName)} className='profile-avatar' />
                                              :
                                              <img src={i.SharedWithProfilePictureUrl} className='profile-avatar' />
                                          }

                                        </div>
                                        <div className="share-user-inf">
                                          <ListItemText primary={`${i.SharedWithName}`} />
                                          <p className="p-0 email">{i.SharedWithEmail}</p>
                                        </div>
                                      </div>
                                    </ListItem>
                                  )
                                  )}
                                </List>}
                              </>
                            }
                          </div>

                        </div>
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="lms-tabs-sec">
              <Detailtabs props={tutorialsDetail} success={handleSuccess} />
              <div className="lms-second-slider ab">
                <div
                  className="card-heading res-sim-heading similarvideos"
                  id="card-headingspace"
                >
                  Similar Videos
                </div>
                <div className="lms-slider-space">
                  <Videoslider props={tutorialsDetail.RelatedVideos} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
};

const mapStateToProps = (state, ownProps) => {
  return {
    tutorialsDetail: state.tutorials.tutorialsDetail,
    loaded: state.tutorials.loaded,
    tutorialsDetailLoaded: state.tutorials.tutorialsDetailLoaded,
    savedTutorials: state.tutorials.savedTutorials,
    users: state.allusers.allUsers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserAction: (payload) => dispatch(getAllUsers(payload)),
    fetchTutorialsDetail: (id) => dispatch(fetchTutorialsDetail(id)),
    fetchSavedTutorials: (id) => dispatch(fetchSavedTutorials(id)),
    resetTutorialDetails: () => dispatch(resetTutorialDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorialDetail);