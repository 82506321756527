import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import ProgressBar from "../Common/ProgressBar";
import ErrorTab from "../Common/ErrorTab";

export const InProgressCoursesList = (props) => {
  console.log(props.props)

  if (props.props && props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((course, idx) => {
          console.log(course)
          return (
            <li className="card-space" key={course.Id}>
              <Link to={"/course/" + course.PlaylistId + "/0"} className={"col-11"} id={"listcard" + idx}>
                <div className="card-img">
                  <img src={course.ThumbnailUrl} alt="lms-logo" />
                </div>
                <ProgressBar className = "in-progress-bar" value={course.PercentageOfCourseCompleted}></ProgressBar>
                <div className="card-info lms-flex-between">
                  <div className="card-title">
                    {course.PlaylistCategoryName}
                  </div>
                  <div className="card-date">
                    {Moment(course.CreatedDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="card-heading">{course.Title}</div>
                <div className="card-para">{course.Description}</div>

              </Link>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return <ErrorTab class={'no-result-saved-item'}/>
  }
};

export default InProgressCoursesList;
