import React, { Component } from "react";
import { connect } from "react-redux";
import { postTutorialVideo, videoUploadCheck } from "../store/actions/admin";

class CreateUploadCourse extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { progress } = this.props;
    console.log(this.props)
    return (
      <React.Fragment>
        <section className="container video-upload-con">

          {
            this.props.selectedVideos.map((i) => (
              <div className="upload-status m-30 mt-30">
                <aside>
                  {/* <img src={this.state.files[0]}  alt="file"/> */}

                  <div className="upload-ststus-details m-0 lms-flex-start">
                  <div style={{width: 10, height: 10 , marginRight: "10px", borderRadius: '50%', backgroundColor: 'green', alignSelf:"center"}}/>
                  <div className="col-3">
                    <img
                      className="video-thumb"
                      src={i.ThumbnailUrl}
                      alt="video-thumbnail"
                    />
                    </div>
                    <div className="video-details">
                      {/* <p>{i.name}</p> */}
                      {`${i.FileName}.${i.Extension}`}

                      {/* <p>80%<span> 12 minutes remaining</span></p> */}
                    </div>
                    <div className="video-button lms-flex-between">
                    <svg id = "edit-icon" width="39" height="44" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd" opacity=".496">
                                <rect
                                    className="edit-icon-stroke"
                                    stroke-width=".9"
                                    x=".45"
                                    y=".45"
                                    width="38.1"
                                    height="38.1"
                                    rx="7.2"
                                />
                                <g className="edit-icon-svg">
                                    <g fill-rule="nonzero">
                                        <path d="m13.426 24.603 3.004-.814 8.26-8.26 1.549-1.55-2.417-2.416-1.574 1.575-8.217 8.216-.605 3.249zm9.628-14.029c.403-.402 1.066-.384 1.493.043l2.639 2.639c.421.421.437 1.097.042 1.492L17.202 24.774l-.253.146L12.969 26c-.537.145-.984-.34-.892-.835l.827-4.44 10.15-10.15zM13.275 29h8.33c.451 0 .817-.308.817-.687 0-.38-.366-.687-.817-.687h-8.33c-.452 0-.817.307-.817.687 0 .38.365.687.817.687z" />
                                    </g>
                                    <path d="m13.72 20.719-.736 4.038 4.102-.723 9.686-9.986-2.995-2.813z" />
                                </g>
                            </g>
                        </svg>
                    </div>
                  </div>
                  {/* <Videoprogressbar progress={progress} /> */}
                </aside>
              </div>
            ))
          }


          {/* <Videodetails /> */}
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  progress: state.tutorials.progress,
  videoData: state.tutorials,
});

const mapDispatchToProps = {
  postTutorialVideoAction: postTutorialVideo,
  videoUploadCheckAction: videoUploadCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUploadCourse);
