import React from 'react'
import QuizSearchFilter from './SearchFilter';
import QuizDataGrid from "./Datagrid";

export default function QuizDataGridData(props) {
  const [userSelection, setUserSelection] = React.useState([])
  const [quizSearchValue, setQuizSearchValue] = React.useState("");
  const [filterQuiz, setFilterQuiz] = React.useState({
    Count: 0,
    Data: []
  });
  const [isOpen, setIsOpen] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [alert, setAlert] = React.useState({
    active: false,
    severity: '',
    message: ''
  });



  const handleFilterQuiz = (event) => {
    setFilterQuiz({
      Count: event.Count,
      Data: event.Data
    });
  };

  React.useEffect(() => {
    setFilterQuiz({
      Count: 0,
      Data: []
    });
    setQuizSearchValue('')
  }, [window.location.pathname.split('/').pop()])


  const handleModalAction = (event) => {
    setIsOpen(event)
  };

  const handleQuizSearch = (event) => {
    setQuizSearchValue(event)
  };

  const handleSuccess = (event) => {
    setSuccess(event)
  };

  const handleAlert = (event) => {
    setAlert(event)
  };


  return (
    <>
      <QuizSearchFilter selection={userSelection} filterData={handleFilterQuiz} modal={handleModalAction} searchValue={handleQuizSearch} success={handleSuccess} alert={handleAlert} modalClose={props.modalClose} />
      <QuizDataGrid searchValue={quizSearchValue} alertCheck={alert} filterData={filterQuiz} edit={props.handleEdit} success={success} />
    </>
  )
}
