import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { bindActionCreators } from "redux";
import CollectionTabs from "../Admin/CollectionTabs";
import { fetchAdminCourse, fetchAdminSettings, fetchAdminVideo, fetchAllGroups, fetchQuestionLevel, fetchQuestions, fetchQuiz, getAllRoles } from "./store/actions/admin";
import { getAllUsers } from "../../store/actions/AllUsersAction";
import { Modal } from '@mui/material';
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import API from "./utils/API";

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktopWidth: 0,
      open: window.innerWidth < 991 ? true : false,
      isDesktop: window.innerWidth > 991 ? true : false
    }
  }
  style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 283,
    height: 227,
    bgcolor: "#ffffff",
    border: 0,
    boxShadow: 24,
    p: 0,
  };
  componentDidMount() {
    const { fetchAllUserAction, fetchQuestionAction, quizPageNumber, fetchQuestionLevelAction, fetchQuizAction, fetchAllGroups, fetchAdminSettingsAction, groupPageNumber, fetchRolesAction, fetchAdminVideoAction, fetchAdminCourseAction, videoCollectionPageNumber, courseCollectionPageNumber } = this.props;
    fetchAllUserAction({
      PageNumber: 1,
      PageSize: 20
    })
    fetchRolesAction({
      PageNumber: 1,
      PageSize: 0
    })
    videoCollectionPageNumber === 2 &&
      fetchAdminVideoAction({
        pageNumber: 1
      });
    courseCollectionPageNumber === 2 &&
      fetchAdminCourseAction(1);
    quizPageNumber === 2 &&
      fetchQuizAction({
        PageNumber: 1,
        PageSize: 20
      });
    quizPageNumber === 2 &&
      fetchQuestionAction({
        Pagination: {
          PageNumber: 1,
          PageSize: 20
        }
      });
    groupPageNumber === 2 &&
      fetchAllGroups({
        PageNumber: 1,
        PageSize: 20
      });
    fetchAdminSettingsAction()
    fetchQuestionLevelAction()
    // fetchCategories();
    // fetchPlaylistCategories();
    window.addEventListener("resize", this.winowWidthUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.winowWidthUpdate)
  }

  winowWidthUpdate = () => {
    const windowWidth = window.innerWidth
    if (windowWidth < 991) {
      return this.setState({ isDesktop: false, open: true });
    }
    else
      return this.setState({ isDesktop: true, open: false });
  }
  handleClose = () => {
    this.setState({ open: false, isDesktop: false });
    return <Navigate to={"/"} replace={true} />
  }
  render() {
    const { role } = this.props;
    const { isDesktop, open } = this.state
    if (role.toLowerCase() != 'admin') {
      return <Navigate to={"/"} replace={true} />
    }
    return (
      <>
        {isDesktop ?
          <section id="admin-top-navbar" className="m-30">
            <div className="admin-navbar  lms-flex-between">
              <CollectionTabs />
            </div>
          </section>
          :
          <NavLink to={"/"}><Modal
            open={open}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={this.style}>
              <div className="alert-close-icon">
                <NavLink to={"/"}><svg onClick={this.handleClose} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M9.21739 8L15.7101 1.47826C16.058 1.13043 16.058 0.57971 15.7101 0.26087C15.3623 -0.0869565 14.8116 -0.0869565 14.4928 0.26087L8 6.78261L1.47826 0.26087C1.13043 -0.0869565 0.57971 -0.0869565 0.26087 0.26087C-0.0869565 0.608696 -0.0869565 1.15942 0.26087 1.47826L6.75362 8L0.26087 14.5217C-0.0869565 14.8696 -0.0869565 15.4203 0.26087 15.7391C0.434783 15.913 0.666667 16 0.869565 16C1.07246 16 1.30435 15.913 1.47826 15.7391L8 9.21739L14.5217 15.7391C14.6957 15.913 14.9275 16 15.1304 16C15.3333 16 15.5652 15.913 15.7391 15.7391C16.087 15.3913 16.087 14.8406 15.7391 14.5217L9.21739 8Z" fill="black" fill-opacity="0.64" />
                </svg></NavLink>
              </div>
              <div className="admin-alert-mess">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                  <path d="M21 3.5C11.3575 3.5 3.5 11.3575 3.5 21C3.5 30.6425 11.3575 38.5 21 38.5C30.6425 38.5 38.5 30.6425 38.5 21C38.5 11.3575 30.6425 3.5 21 3.5ZM19.6875 14C19.6875 13.2825 20.2825 12.6875 21 12.6875C21.7175 12.6875 22.3125 13.2825 22.3125 14V22.75C22.3125 23.4675 21.7175 24.0625 21 24.0625C20.2825 24.0625 19.6875 23.4675 19.6875 22.75V14ZM22.61 28.665C22.5225 28.8925 22.4 29.0675 22.2425 29.2425C22.0675 29.4 21.875 29.5225 21.665 29.61C21.455 29.6975 21.2275 29.75 21 29.75C20.7725 29.75 20.545 29.6975 20.335 29.61C20.125 29.5225 19.9325 29.4 19.7575 29.2425C19.6 29.0675 19.4775 28.8925 19.39 28.665C19.3001 28.4547 19.2525 28.2287 19.25 28C19.25 27.7725 19.3025 27.545 19.39 27.335C19.4775 27.125 19.6 26.9325 19.7575 26.7575C19.9325 26.6 20.125 26.4775 20.335 26.39C20.7611 26.215 21.2389 26.215 21.665 26.39C21.875 26.4775 22.0675 26.6 22.2425 26.7575C22.4 26.9325 22.5225 27.125 22.61 27.335C22.6975 27.545 22.75 27.7725 22.75 28C22.75 28.2275 22.6975 28.455 22.61 28.665Z" fill="black" />
                </svg>
                <span>Switch to web application to access the admin features</span>
              </div>
            </Box>
          </Modal>
          </NavLink>
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.profileData.Role,
  videoCollectionPageNumber: state.tutorials.collectionPageNumber,
  courseCollectionPageNumber: state.courses.collectionPageNumber,
  groupPageNumber: state.groups.groupPageNumber,
  quizPageNumber: state.quiz.QuizPagenumber,
  questionPageNumber: state.questions.QuestionPageNumber,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchAllUserAction: getAllUsers,
    fetchRolesAction: getAllRoles,
    fetchAdminVideoAction: fetchAdminVideo,
    fetchAdminCourseAction: fetchAdminCourse,
    fetchQuizAction: fetchQuiz,
    fetchQuestionLevelAction: fetchQuestionLevel,
    fetchAllGroups: fetchAllGroups,
    fetchAdminSettingsAction: fetchAdminSettings,
    fetchQuestionAction: fetchQuestions,
  }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

