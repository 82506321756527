import React from "react";
import Slider from "react-slick";
import Moment from "moment";
import { Link } from "react-router-dom";

const Videoslider = (props) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: false,
    // nextArrow: '.next_arrow_img',
    // prevArrow: '.previous_arrow_img',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: 'unslick'
      },
    ],
  };

  React.useEffect(() => {
    // coursesDetail.filter(a => a.Status === 'InProgress').length > 0 &&
    //   navigate("/course/" + id + "/" + (coursesDetail.filter(a => a.Status === 'InProgress')[0].IndexAt - 1))
    console.log(props.props)
    // API.getRelatedCourse(coursesDetail[videoId].PlalistCategory).then(res => setRelatedCourse(res.data))
  }, []);


  return (
    <Slider {...settings}>
      {props.props?.map((RelatedCourse, idx) => {
        return (
          <div className="lms-card-w" key={idx}>
            <div className="col-11 col-xs-12">
              <Link to={"/course/" + RelatedCourse.Id + "/0"}>
                <div className="card-img">
                  <img
                    src={RelatedCourse.ThumbnailUrl}
                    alt="ThumbnailUrl"
                  ></img>
                </div>
                <div className="card-info lms-flex-between">
                  <div className="card-title card-title-w">
                    {RelatedCourse.PlaylistCategoryName}
                  </div>
                  <div className="card-date lms-carddate">
                    {Moment(RelatedCourse.CreatedDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="card-heading card-heading-m">
                  {RelatedCourse.Title}
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
export default Videoslider;
