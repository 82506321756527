import React from "react";

function SearchResponsive() {
  return (
    <section id="universal-search">
      <div className="universal-search-sec lms-flex-align-center">
        <div className="universal-search-w1">
          <form className="lms-dashboard-form" action="">
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
            <input type="text" placeholder="Search Everything" name="search" />
          </form>
        </div>
        <div className="universal-search-w2">
          <div className="universal-search-close">
            <img src="assets/close-icon.png" alt="lms-logo" />
          </div>
        </div>
      </div>
    </section>
  );
}
export default SearchResponsive;
