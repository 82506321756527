import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Selectcategory from "../Video/Selectcategory";
import { connect } from "react-redux";
import {
  fetchAdminCourse,
  updateDocumentVideo,
  updateTutorialVideo
} from "../store/actions/admin";
import API from "../utils/API";
import Selectplaylistcategory from "./SelectPlaylistCategory";
import { useNavigate } from "react-router-dom";

class VerticalTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      tags: "",
      playlistId: "",
      tagsError: "",
      description: "",
      transcript: "",
      titleError: "",
      descriptionError: "",
      transcriptError: "",
      categoryId: null,
      isInputFocus: false
    };
    console.log(this.props);
  }

  validate = () => {
    let titleError = "";
    let descriptionError = "";
    let tagsError = "";

    if (this.state.title.length === 0) {
      titleError = "Enter the heading";
    }

    if (this.state.description.length === 0) {
      descriptionError = "Enter the description";
    }

    if (this.state.tags.length === 0) {
      tagsError = "Enter the Tags";
    }

    if (titleError || descriptionError || tagsError) {
      this.setState({ titleError, descriptionError, tagsError });
      return false;
    }

    return true;
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleInpActive = () => {
    this.setState({ isInputFocus: true, isInpDisabled: false });
  };

  handleCategoryId = e => {
    console.log(e);
    console.log(this.props);

    this.setState({
      categoryId: e
    });
  };

  handleUploadClick = () => {
    const { selectVideo, selectedVideos } = this.props;

    const isValid = this.validate();
    if (isValid) {
      var formData = new FormData();
      formData.append("Title", this.state.title.trim());
      formData.append("Description", this.state.description.trim());
      formData.append("PlaylistCategoryId", this.state.categoryId);
      formData.append("Tags", "");
      // formData.append("filecontent", thumbnail[0]);
      formData.append("CourseType", this.props.courseType);
      API.createPlaylist(formData)
        .then(res => {
          if (res.status === 200) {
            Promise.all(
              selectedVideos.map((i, index) => {
                return API.addVideoToPlaylist({
                  PlaylistId: res.data.CourseId,
                  VideoId: i.Id,
                  Order: index
                });
              })
            ).then(() => {
              var updateForm = new FormData();
              updateForm.append("Id", res.data.CourseId);
              updateForm.append("CategoryId", this.state.categoryId);
              API.updateCourseData(updateForm);
            });
          }
        })
        .finally(() => {
          this.props.fetchAdminCourseAction(1);
        })
        .then(() => {
          this.props.navigate("/admindashboard/coursecollection");
        });
      this.setState({
        title: "",
        description: "",
        transcript: "",
        titleError: "",
        descriptionError: "",
        transcriptError: ""
      });
    }
  };

  render() {
    return (
      <Timeline>
        <TimelineItem className="time-line-content">
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="video-desc lms-flex-between">
              <h4>Add Course Title</h4>
            </div>
            <div
              className={`video-input ${this.state.isInputFocus
                ? "is-inp-focus"
                : "is-inp-not-focus"}`}
            >
              {" "}<input
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.handleInputChange}
                onFocus={this.handleInpActive}
              />
            </div>
            <h6 style={{ color: "red" }}>
              {this.state.titleError}
            </h6>
            <div className="video-upload thumb-upload">
              <svg
                width="64"
                height="64"
                viewBox="0 0 84 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="64"
                  height="64"
                  rx="17.6"
                  className="svg-for-menu"
                />
                <rect
                  x="18.3994"
                  y="15.2001"
                  width="25.6"
                  height="33.6"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z"
                  className="svg-for-menu"
                />
              </svg>
              <h4>Upload thumbnail image</h4>
            </div>
            <Selectplaylistcategory
              onSeletecCategoryId={this.handleCategoryId}
            />
          </TimelineContent>
        </TimelineItem>

        <TimelineItem className="time-line-content">
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="video-desc lms-flex-between">
              <h4>Add Tags</h4>
            </div>
            <div className="video-input">
              {" "}<input
                type="text"
                name="tags"
                value={this.state.tags}
                onChange={this.handleInputChange}
              />
            </div>
            <h6 style={{ color: "red" }}>
              {this.state.tagsError}
            </h6>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem className="time-line-content">
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <h4>Description</h4>
            <textarea
              id="video-descripation"
              rows="12"
              cols="50"
              form="usrform"
              placeholder="Add Description"
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange}
            />
            <h6 style={{ color: "red" }}>
              {this.state.descriptionError}
            </h6>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          {/* <TimelineContent>
            <h4 className="m-30">Documents</h4>
            <DraganddropCourse VideoId = {this.props.selectVideo.VideoId} FileId={this.props.selectVideo.FileId}/> */}
          {/* <div className="drappedfile lms-flex-between mt-30">
            <Drappedfile/>
            <Draganddrop/>  
            </div> */}
          {/* </TimelineContent> */}
        </TimelineItem>
        <TimelineItem className="time-line-content">
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <h4>Transcript</h4>
            <textarea
              id="video-descripation"
              rows="12"
              cols="50"
              form="usrform"
              placeholder="  Add Transcript"
              name="transcript"
              value={this.state.transcript}
              onChange={this.handleInputChange}
            />

            <div className="d-flex justify-content-end lms-flex-end mt-30">
              <button className="pri-button">Save</button>
              <div className="upload-btn-width d-flex justify-content-end">
                <button className="pri-button" onClick={this.handleUploadClick}>
                  Upload
                </button>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    );
  }
}

const mapStateToProps = state => {
  const {
    VideoData,
    VideoName,
    DocumentData,
    VideoExtension,
    DocumentExtension,
    DocumentName,
    isVideoUploaded
  } = state.tutorials;
  return {
    VideoData: VideoData,
    DocumentData: DocumentData,
    VideoName: VideoName,
    VideoExtension: VideoExtension,
    isVideoUploaded: isVideoUploaded,
    DocumentExtension: DocumentExtension,
    DocumentName: DocumentName
  };
};

const mapDispatchToProps = {
  updateTutorialVideoAction: updateTutorialVideo,
  updateDocumentVideoAction: updateDocumentVideo,
  fetchAdminCourseAction: fetchAdminCourse
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalTimeline);
