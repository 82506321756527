import React from "react";

export default function TermsAndConditions(){
    const [data,setData] = React.useState([]);

    return (
        <>
        {data.length > 0 ? 
        <div>
            <h1>Terms & conditions</h1>
        </div> :
        <div className="d-flex align-items-center justify-content-center" style={{height:"100vh"}}>
            <h1>No Data</h1>
        </div>
        }
        </>
    )
}