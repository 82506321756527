import { call, put, delay } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../modules/FusionAuth/Utils/API";

export function* getUserDetailsSaga(action) {
    try {
        console.log("in sagas", action);
        // const response = yield call(API.getUserData, action.payload);
        // const profileData = yield response.data;
        // console.log("userdata", response)
        yield put({
            type: actions.GET_USER_DETAILS_SUCCEEDED,
            user: action.payload,
        });
    } catch(error) {
        console.log(error)
        yield put({ type: actions.GET_USER_DETAILS_FAILED, message: error.message });
    }
}