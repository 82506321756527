
import { connect } from "react-redux";
import * as React from "react";
import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetails } from "../../store/actions/userActions";
import store from "../../store/configureStore";
import API from "../../utils/API"
import { getProfileData } from "../../store/actions/profileDataAction";
import { Alert, Backdrop } from "@mui/material";
import { useTranslation } from "react-i18next";
import { languages } from "../../components/Common/Constants";
import Cookies from "js-cookie";
import i18next from "i18next";
import Checkbox from '@mui/material/Checkbox';


function EditProfile(props) {
  let proPic = props.props.props.profile.ProfileImageUrl != null ? props.props.props.profile.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? props.props.props.profile.ProfileImageUrl : sessionStorage.getItem('profilePic') : sessionStorage.getItem('profilePic');

  const inputFileRef = React.useRef();
  const userId = useSelector(() => store.getState().profileData.id);

  const { handleEditBtn } = props.props;
  const [displayPasswordSection] = React.useState(false);
  const [displayNotificationSection] = React.useState(false);

  const [defaultLanguage, setdefaultLanguage] = React.useState(props.props.props.profile.LanguageId);
  const [defaultFirstName, setdefaultFirstName] = React.useState(props.props.props.profile.FirstName);
  const [defaultLastName, setdefaultLastName] = React.useState(props.props.props.profile.LastName);
  const [defaultEmail, setdefaultEmail] = React.useState(props.props.props.profile.Email);

  const [language, setlanguage] = React.useState("");
  const [firstName, setfirstName] = React.useState("");
  const [lastName, setlastname] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [deleteBtnDisable, setDeleteBtnDisable] = React.useState(false);  
  const [profilepicture, setProfilePicture] = React.useState('');
  const [backdropOpen, setbackDropOpen] = React.useState(false);
  const [success, setSuccess] = React.useState({
    status: false,
    type: '',
    message: ''
  });

  const isRightToLeft = document.body.dir === "rtl"


  const { t } = useTranslation();

  const profile_Picture_Upload_Warning = t("profile_Picture_Upload_Warning")
  const profile_Picture_Uploaded = t("profile_Picture_Uploaded")
  const profile_Picture_Deleted = t("profile_Picture_Deleted")
  const profile_Updated = t("profile_Updated")
  const Upload_New = t("Upload_New")
  const Delete = t("Delete")
  const Save = t("Save")
  const Cancel = t("Cancel")
  const Firstname = t("Firstname")
  const Lastname = t("Lastname")
  const Designation = t("Designation")
  const Email = t("Email")
  const Langugage_Preference = t("Langugage_Preference")
  const General = t("General")



  const [isEngChecked, setisEngChecked] = React.useState(defaultLanguage == 0 ? true : false);
  const handleLangChange = () => { setisEngChecked(!isEngChecked); setlanguage(isEngChecked ? 1 : 0); /*alert(language)*/ };
  const handleFirstNameChange = (e) => { setfirstName(e); };
  const handleLastNameChange = (e) => { setlastname(e); };
  const handleEmailChange = (e) => { setEmail(e); };
  const handleSave = () => {
    Cookies.set('i18next', languages[language].code)
    if(firstName !== defaultFirstName || lastName !== defaultLastName || email !== defaultEmail || language !== defaultLanguage)
    {
      API.updateUserProfile({
        UserId: props.props.props.profile.id,
        Name: firstName + " " + lastName,
        FirstName: firstName,
        LastName: lastName,
        LanguageId: language,
        Email: email
      }).then(res => (
        res.status === 201 &&
        props.getProfileData(props.profile.id)
      )).then(
        setSuccess({
          status: true,
          type: 'success',
          message: profile_Updated
        })
      )
        .then(handleEditBtn)
        .then(Cookies.get('i18next'))
        .finally(
          i18next.changeLanguage(Cookies.get('i18next'))
        )
    }
  }

  const onBtnClick = (e) => {
    /*Collecting node-element and performing click*/
    inputFileRef.current.click();
  };

  const handleprofilePicture = (event) => {
    if (event.target.files[0].type.split('/')[0] === 'image') {
      API.updateProfilePicture({
        userId: userId,
        file: event.target.files[0]
      }).then((res) => {
        props.getProfileData(props.profile.id)
        handleSuccess()
      })
    }
    else {
      setSuccess({
        status: true,
        type: 'error',
        message: profile_Picture_Upload_Warning
      })
    }
  }

  const handleDeleteProfilePicture = (event) => {
    API.deleteProfilePicture({
      userId: userId,
    }).then((res) => {
      props.getProfileData(props.profile.id)
      setSuccess({
        status: true,
        type: 'error',
        message: profile_Picture_Deleted
      })
    })
  }



  const handleSuccess = (data) => {
    setSuccess({
      status: true,
      type: 'success',
      message: profile_Picture_Uploaded
    })
    handleBackDropOpen(true)
  }

  React.useEffect(() => {
    setTimeout(() => {
      handleBackDropClose()
    }, 4000);
  }, [backdropOpen])

  const handleBackDropOpen = (data) => {
    setbackDropOpen(data);
  };

  const handleBackDropClose = () => {
    setbackDropOpen(false);
    setSuccess({
      status: false,
      type: '',
      message: ''
    })
  };

  React.useEffect(() => {
   let btn = proPic.includes(process.env.REACT_APP_AZURE_BLOB_BASE_URL)
   setDeleteBtnDisable(btn)   
  }, [proPic])
  

  return (
    <div className="edit-profile">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={success.status}
        onClick={handleBackDropClose}
      >
        <Alert severity={success.type} style={{ position: "absolute", top: 20 }}>
          {`${success.message}! `}
        </Alert>
      </Backdrop>
      <div className="user-personal-details">
        <h6>{General}</h6>
        <div className="lms-flex align-items-center personal-info">
          {/* <div className="profile-image" > */}
          <img src={proPic} alt="profile" className="profile-image" />
          {/* </div> */}
          <div className="profile-btn-cont">
            <div className="col-4 col-md-6 d-flex justify-content-center upload-img " >
              <button className="primary-button sec-btn" onClick={() => { document.getElementById('uploadinput').click() }}>{Upload_New}</button>
              <input id="uploadinput" style={{ display: 'none' }}
                onChange={(e) => handleprofilePicture(e)}
                onClick={(event) => {
                  event.target.value = null
                }}
                type="file" />
            </div>

            <div className="delete-img d-flex justify-content-center">
              <button
                className="primary-button ter-btn edit-del"
                disabled={!deleteBtnDisable}
                onClick={handleDeleteProfilePicture}>{Delete}</button>
            </div>
          </div>
        </div>
        <div className="personal-details">
          <div className="row lms-flex">
            <div className="col1">
              <label for="fname">{Firstname}</label>
              <br />
              <input type="text" id="fname" name="fname" className="m-0" placeholder={defaultFirstName} disabled onChange={(e) => handleFirstNameChange(e.target.value)}/>
              <br />
            </div>
            <div className="col2">
              <label for="lname">{Lastname}</label>
              <br />
              <input type="text" id="lname" className="m-0" name="lname" placeholder={defaultLastName} disabled onChange={(e) => handleLastNameChange(e.target.value)}/>
              <br />
            </div>
          </div>
          <div className="row lms-flex">
            <div className="col1">
              <label for="email">{Email}</label>
              <br />
              <input type="text" id="email" className="m-0" name="email" placeholder={defaultEmail} disabled onChange={(e) => handleEmailChange(e.target.value)}/>
              <br />
            </div>
            <div className="col1">
              <label for="designation">{Designation}</label>
              <br />
              <input type="text" id="designation" name="designation" className="m-0" value={props.props.props.profile.Role} disabled />
              <br />
            </div>
          </div>

        </div>
      </div>
      {displayPasswordSection &&
        <div className="user-personal-details user-password-details">
          <h6>Password</h6>

          <div className="personal-details">
            <div className="row lms-flex">
              <div className="col1">
                <label for="oldpswd">Old Password*</label>
                <br />
                <input type="text" id="oldpswd" className="m-0" name="oldpswd" required />
                <br />
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col1">
                <label for="newpswd">New Password*</label>
                <br />
                <input type="text" id="newpswd" className="m-0" name="newpswd" required />
                <br />
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col1">
                <label for="reenterpswd">Re-enter New Password*</label>
                <br />
                <input
                  type="text"
                  id="reenterpswd"
                  name="reenterpswd"
                  className="m-0"
                  required
                />
                <br />
              </div>
            </div>
          </div>
        </div>
      }
      <div className="user-personal-details user-password-details">
        <h6>{Langugage_Preference}</h6>

        <div className="personal-details">
          <div className=" lms-flex language-cont">
            <div className={`col-6 col-xs-12 ${isRightToLeft ? "languge-border-l" : "languge-border-r"}`}>
              <label className="checkbox-container">
                <Checkbox checked={isEngChecked} onChange={handleLangChange} value={0}/>
                <img src="assets/english-us-logo.png" className="lang-img lang-img-edit" />
                <p>English (US)</p>
              </label>
            </div>
            <div className={`col-6 col-xs-12 ${isRightToLeft ? "lang-margin-r" : "lang-margin-l"}`}>
              <label className="checkbox-container">
               <Checkbox checked={!isEngChecked} onChange={handleLangChange} value={1}/>
                <img src="assets/arabic-logo.png" className="lang-img lang-img-edit" />
                <p>Arabic</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      {displayNotificationSection &&
        <div className="user-personal-details user-password-details">
          <h6>Alerts & Notifications</h6>

          <div className="personal-details">
            <div className="row lms-flex">
              <div className="col3">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>New Course or Video added</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Account Activity</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Shared videos by others</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <h6 className="email-notification">Email Notifications</h6>

            <div className="row lms-flex">
              <div className="col3">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>New Course or Video added</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Account Activity</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="row lms-flex">
              <div className="col4">
                <label className="checkbox-container">
                  <input type="checkbox" />

                  <p>Shared videos by others</p>
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

        </div>
      }
      <div className="action-items">
        <div className="col-xl-2"><button className="primary-button ter-btn" onClick={handleEditBtn}>{Cancel}</button></div>
        <div className="col-xl-2 col-md-3 d-flex justify-content-end save-btn-wd"><button className="primary-button sec-btn save-btn" onClick={handleSave}>{Save}</button></div>
      </div>

    </div>
  );
}

const mapStateToProps = (state) => ({
  profile: state.profileData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProfileData: getProfileData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
