import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import AppRouter from "./router/AppRouter";
import "./index.css";
import reportWebVitals from "./reportWebVitals"
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/authConfig";
import persistStore from 'redux-persist/es/persistStore';
import store from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { FusionAuthProvider, RequireAuth } from '@fusionauth/react-sdk';
import  FusionAuthProviderWithRedirectHandling  from './FusionAuthProviderWithRedirectHandling';


i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/languages/{{lng}}/translation.json',
    },
  });


let persistor = persistStore(store);
const root = createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <Provider store={store}>
    <FusionAuthProviderWithRedirectHandling >
      <PersistGate loading={<div> Loading...</div>} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </FusionAuthProviderWithRedirectHandling>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
