import actions from "./actionTypes";


export const blueBarAction = (payload) => {
    return {
      type: actions.HANDLE_BLUEBAR_START,
      payload
    };
  };

  export const blueBarCourseAction = (payload) => {
    return {
      type: actions.HANDLE_BLUEBAR_COURSE_START,
      payload
    };
  };