import { call, put, delay } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../utils/API";

export function* fetchTutorialsSaga(action) {
  try {
    const response = yield call(API.fetchTutorials);
    const tutorials = yield response.data;

    yield put({
      type: actions.FETCH_TUTORIALS_SUCCEEDED,
      tutorials: tutorials,
    });
  } catch (error) {
    console.log(error)
    yield put({ type: actions.FETCH_TUTORIALS_FAILED, message: error.message });
  }
}

export function* fetchSavedTutorialsSaga(action) {
  try {
    console.log(action)
    const response = yield call(API.fetchSavedTutorials, action.payload);
    const savedTutorials = yield response.data;

    yield put({
      type: actions.FETCH_SAVED_TUTORIALS_SUCCEEDED,
      savedTutorials: savedTutorials,
    });
  } catch (error) {
    console.log(error)
    yield put({ type: actions.FETCH_SAVED_TUTORIALS_FAILED, message: error.message });
  }
}

export function* fetchTutorialsLoadMoreSaga(action) {
  try {
    const response = yield call(API.fetchTutorialsLoadMore, action.payload);
    const tutorials = yield response.data;
    yield delay(1000);

    if (tutorials != "") {
      yield put({
        type: actions.FETCH_TUTORIALS_LOADMORE_SUCCEEDED,
        tutorials: tutorials,
      });
    }
    else {
      yield put({
        type: actions.FETCH_TUTORIALS_LOADMORE_FINISHED,
        lazyloader: false,
      });
    }

  } catch (error) {
    yield put({
      type: actions.FETCH_TUTORIALS_FAILED,
      message: error.message
    });
  }
}

export function* fetchTutorialsDetailSaga(action) {
  try {
    const response = yield call(API.fetchTutorialsDetail, action.id);
    const tutorialsDetail = yield response.data;
    yield put({
      type: actions.FETCH_TUTORIALS_DETAIL_SUCCESS,
      tutorialsDetail: tutorialsDetail,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: action.FETCH_TUTORIALS_DETAIL_FAILED,
      message: error.message
    });
  }
}

export function* filterTutorialsSaga(action) {
  try {
    const response = yield call(API.filterTutorials, action.payload);
    const tutorials = yield response.data;
    yield put({
      type: actions.FILTER_TUTORIALS_SUCCEEDED,
      tutorials: tutorials,
    });
  } catch (error) {
    yield put({
      type: actions.FILTER_TUTORIALS_FAILED,
      message: error.message
    });
  }
}

export function* sortTutorialsSaga(action) {
  try {
    const response = yield call(API.sortTutorials, action.payload);
    const tutorials = yield response.data;
    yield put({
      type: actions.FILTER_TUTORIALS_SUCCEEDED,
      tutorials: tutorials,
    });
  } catch (error) {
    yield put({
      type: actions.FILTER_TUTORIALS_FAILED,
      message: error.message
    });
  }
}

export function* searchTutorialsSaga(action) {
  try {
    const response = yield call(API.searchTutorials, action.payload);
    const tutorials = yield response.data;
    yield put({
      type: actions.FILTER_TUTORIALS_SUCCEEDED,
      tutorials: tutorials,
    });
  } catch (error) {
    yield put({
      type: actions.FILTER_TUTORIALS_FAILED,
      message: error.message
    });
  }
}

export function* fetchSharedByMeVideosSaga(action) {
  try {
    const response = yield call(API.getSharedByMeVideos, action.payload);
    const sharedbymevideos = yield response.data;

    yield put({
      type: actions.FETCH_SHAREDBYME_VIDEOS_SUCCEEDED,
      sharedbymevideos: sharedbymevideos,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_SHAREDBYME_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchSharedWithMeVideosSaga(action) {
  try {
    const response = yield call(API.getSharedWithMeVideos, action.payload);
    const sharedwithmevideos = yield response.data;

    yield put({
      type: actions.FETCH_SHAREDWITHME_VIDEOS_SUCCEEDED,
      sharedwithmevideos: sharedwithmevideos,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_SHAREDWITHME_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchSavedVideosLoadMoreSaga(action) {
  try {
    const response = yield call(API.fetchSavedTutorials, action.payload);
    const savedTutorials = yield response.data;
    yield delay(1000);

    if (savedTutorials != "") {
      yield put({
        type: actions.FETCH_SAVED_LOADMORE_TUTORIALS_SUCCEEDED,
        savedTutorials: savedTutorials,
      });
    }
    else {
      yield put({
        type: actions.FETCH_SAVED_LOADMORE_TUTORIALS_FINISHED,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_SAVED_LOADMORE_TUTORIALS_FAILED,
      message: error.message
    });
  }
}

export function* fetchSharedBymeVideosLoadMoreSaga(action) {
  try {
    const response = yield call(API.getSharedByMeVideos, action.payload);
    const sharedbymevideos = yield response.data;
    yield delay(1000);

    if (sharedbymevideos != "") {
      yield put({
        type: actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_SUCCEEDED,
        sharedbymevideos: sharedbymevideos,
      });
    }
    else {
      yield put({
        type: actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_FINISHED,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_SHAREDBYME_VIDEOS_LOADMORE_FAILED,
      message: error.message
    });
  }
}
export function* fetchSharedWithmeVideosLoadMoreSaga(action) {
  try {
    const response = yield call(API.getSharedWithMeVideos, action.payload);
    const sharedbymevideos = yield response.data;
    yield delay(1000);

    if (sharedbymevideos != "") {
      yield put({
        type: actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_SUCCEEDED,
        sharedbymevideos: sharedbymevideos,
      });
    }
    else {
      yield put({
        type: actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FINISHED,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FAILED,
      message: error.message
    });
  }
}


export function* resetVideoTutorialSaga(action) {
  try {
    yield put({
      type: actions.RESET_TUTORIALS_DETAILS_SUCCEEDED,
      data: [],
    });
  } catch (error) {
  }
}