import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

function UserDashboard(props) {
  const { t } = useTranslation();

  const EditProfile = t("Edit_Profile")

  const { handleEditBtn } = props;
  let proPic = props.props.profile.ProfileImageUrl != null ? props.props.profile.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? props.props.profile.ProfileImageUrl : "assets/profile-icon.jpg" : "assets/profile-icon.jpg";
  return (
    <div>
      <div className="lms-user-dashboard">
        <div className='blu-bar-height'>
        <div className="lms-user-details lms-bluecontainer-sec">
        {/* <div className="lms-close-icon">
          <img src="../assets/close-icon.png" alt="lms-logo" />
      </div> */}
      <div className="d-flex align-items-center lms-r-flex lms-blue-res justify-content-between" style={{width:"100%"}}>
        <div className="col-4 blue-cont-res">
          <div className="d-flex align-items-center lms-flex-l">
            <div className="col-3 blu-img-cont">
              <div className="blue-image1">
                <img src={proPic} alt="lms-logo" />
              </div>
            </div>
            <div className=" profile-para lms-l-c2">
              <h1 className="profile-heading">{props.props.profile.Name}</h1>
              <p className="d-block p-0">
              {props.props.profile.Email}
              </p>
              <p className="d-block p-0">
              {props.props.profile.roles && props.props.profile.roles[0]}
              </p>
            </div>
          </div>
        </div>
        <div className="blue-cont-res">
          <div className="d-flex align-items-center blu-btn-cont lms-flex-m ">
            <div className="lms-m-c2">
              <p className="progress-statistics-text ">
              {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium error doloremque laudantium error sit ut…. */}
              </p>
            </div>
            <div className="lms-r-c2 ">
              <button className="primary-button btn-edit-profile" onClick={handleEditBtn}> {EditProfile}</button>
            </div>
          </div>
        </div>
      </div>
        </div>
        </div>
        </div>
    </div>
  );
}

export default UserDashboard;