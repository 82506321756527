import React, { Component } from "react";
import UserNameLabel from "../../components/TranslatedComponents/UserNameLabel";
import ProfileMenuLabel from "../../components/TranslatedComponents/ProfileMenuLabel";
import HandleLogout from "../../components/AuthComponents/logout"
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { MsalContext } from "@azure/msal-react";
import LogoutMenulabel from "../../components/TranslatedComponents/LogoutMenulabel";
import { FusionAuthContext } from "@fusionauth/react-sdk";
import { signOutUser } from "../../store/actions/userActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export class UserDropDown extends Component {
  static contextType = FusionAuthContext;

  container = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.logout = this.handleLogout.bind(this);

  }

  handleButtonClick() {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };


  handleLogout() {
    this.context.logout()
      .then(e =>
        this.props.signOutAction()
      )
      .catch(e => {
        console.error(e);
      });
  };

  handleClickOutside = (event) => {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      this.setState({
        open: false,
      });
    }
  };

  componentDidMount() {
    console.log(this.props.props.props.user)
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    // let proPic = this.props.props.props.user.ProfileImageUrl != null ? this.props.props.props.user.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? this.props.props.props.user.ProfileImageUrl : sessionStorage.getItem('profilePic') : sessionStorage.getItem('profilePic');
    return (

      <div className="user-details lms-flex-between" onClick={this.handleButtonClick}>
        <div className="profile-title">{this.props.props.props.user.Name}</div>
        <div className="profile-drop-container" ref={this.container}>
          <div className="profile">
            <img src={this.props.props.props.user.ProfileImageUrl} alt="lms-logo" />
          </div>
          {/* <button type="button" >
            <img src="assets/path.png" alt="lms-logo" />
          </button> */}
          {this.state.open && (<div className={`profileDropDown ${document.body.dir == 'rtl' ? 'ar-profile-drop' : 'en-profile-drop'}`}>
            <ul className="ulProfile">
              <li className="liMenu">
                {/* <a href={"/profile/userinfo"} className=" lms-flex-align-center "> */}
                <NavLink to={"/profile/userinfo"} className=" lms-flex-align-center ">
                  <div className="col-3"><img src="assets/profi-icon.png" className="bootstrap-img" /></div>
                  <ProfileMenuLabel />
                </NavLink>
                {/* </a> */}
              </li>
              <li className="liMenu lms-flex-align-center" onClick={this.logout}>
                {/* <a href="/" className="lms-flex-align-center ">  */}
                <div className="col-3" onClick={this.handleLogout}><img src="assets/logout-icon.png" className="bootstrap-img" /></div>
                <LogoutMenulabel />
                {/* </a> */}
              </li>
              {/* <li className="liMenu"><a href="/" className="lms-flex-align-center "><img src="assets/accout-setting-icon.png"/>Account Settings</a></li>
              <li className="liMenu"><a href="/" className="lms-flex-align-center "><img src="assets/help-icon.png"/>Help</a></li> */}
              {/* <li className="liMenu"><HandleLogout /></li> */}
            </ul>
            <div className="terms-conditions-con">
              <NavLink to={"/" + "/terms&conditions"} target="blank"><p>Terms & Conditions</p></NavLink>
              <NavLink to={"/" + "/privacy&policy"} target="blank"><p>Privacy Policy</p></NavLink>
            </div>
          </div>)}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signOutAction: signOutUser
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(UserDropDown);
