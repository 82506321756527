import actions from "../actions/actionTypes";

let initialState = {
    user: {},
    // loaded:false
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.GET_USER_DETAILS_SUCCEEDED: {
            console.log(action);
            return {
                ...state,
                user: action.user,
                // loaded: true,
            };
        }
        case actions.GET_USER_DETAILS_FAILED: {
            return {
                ...state,
                // loaded: false,
            }
        }
        default:
            return state;
    }
}

export default userReducer;