import actions from "../actions/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_TUTORIALS_FAILED:
      return { message: actions.message };

    case actions.FETCH_COURSES_FAILED:
      return { message: actions.message };

    default:
      return state;
  }
};
