import React from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Modal, Box } from '@mui/material';
import ErrorTab from "../Common/ErrorTab";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const CompletedCoursesList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState('');

  const handleOpen = (e) => {
    setOpen(true)
    setSelectedCourse(e)
  }
  const handleClose = () => setOpen(false);
  console.log(props.props)
  if (props.props && props.props.length > 0) {
    return (
      <React.Fragment>
        {props.props.map((course, idx) => {
          return (
            <li className="card-space" key={course.Id}>
              <Link to={"/course/" + course.PlaylistId + "/0"} className = {"col-11"} id={"listcard" + idx}>
                <div className="card-img">
                  <img src={course.ThumbnailUrl} alt="lms-logo" />
                </div>
                <div className="card-info lms-flex-between">
                  <div className="card-title">
                    {course.PlaylistCategoryName}
                  </div>
                  <div className="card-date">
                    {Moment(course.CreatedDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="card-heading">{course.Title}</div>
                <div className="card-para">{course.Description}</div>

              </Link>
              {/* props.props.certificates.filter(a => a.PlaylistId === course.PlaylistId)[0].CertificateUrl */}
              {/* <div className="certification">
                <button className="primary-button sec-btn btn-view-certificate" onClick={() => handleOpen(course.PlaylistId)}>View Certificate</button>
              </div> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <img src={selectedCourse ? props.props.certificates.filter(a => a.PlaylistId === selectedCourse)[0].CertificateUrl : ''} alt='certificate' />
                </Box>
              </Modal>
            </li>
          );
        })}
      </React.Fragment>
    );
  } else {
    return <ErrorTab class={'no-result-saved-item'}/>
  }
};

export default CompletedCoursesList;
