import React, { Component } from "react";
import { postTutorialDocument } from "../store/actions/admin";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import DocumentCheck from "../Common/DocumentCheck";
import * as constants from "../Common/Constants";


class Draganddrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VideoId: "",
      FileId: "",
      FileName: "",
      File: {}
    };
    this.onDrop = async (files) => {
      if (!constants.acceptedMime.includes(files[0].type)) {
        props.error('Document format incorrect')
      }
      else {
        localStorage.setItem('docs', JSON.stringify({
          'lastModified': files[0].lastModified,
          'lastModifiedDate': files[0].lastModifiedDate,
          'name': files[0].name,
          'size': files[0].size,
          'type': files[0].type,
          'webkitRelativePath': files[0].webkitRelativePath
        }))
        const { VideoId, FileId } = this.props;
        this.setState({
          FileName: files[0].name,
          VideoId,
          FileId,
          File: files[0]
        });
        props.document(files[0])
        props.error('')
      }
    };

  }


  handleChange = (e) => {
    const { VideoId, FileId } = this.props;

    this.setState({
      FileName: e.target.files[0].name,
      VideoId,
      FileId,
    });

    localStorage.setItem('docs', JSON.stringify({
      'lastModified': e.target.files[0].lastModified,
      'lastModifiedDate': e.target.files[0].lastModifiedDate,
      'name': e.target.files[0].name,
      'size': e.target.files[0].size,
      'type': e.target.files[0].type,
      'webkitRelativePath': e.target.files[0].webkitRelativePath
    }))
  };

  componentDidUpdate() {
    const { postTutorialDocumentAction } = this.props;

    if (this.state.FileName) {
      postTutorialDocumentAction(this.state);
      // this.setState({ FileName: "" });
    }
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        {
          this.state.FileName.length > 0 &&
          <DocumentCheck Extension={this.state.FileName.split('.').pop()} Filename ={this.state.FileName.split('.')[0]} CreatedDate = {this.state.File.lastModifiedDate}/>
        }
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropfile" })}>
              <input {...getInputProps()} />
              <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <rect className="svg-for-menu" width="31" height="31" rx="8.448" />
                  <path fill="#FFF" d="M8.912 7.363h12.4v16.275h-12.4z" />
                  <path
                    d="M7.825 6.2h15.35c.894 0 1.625.73 1.625 1.625v15.35a1.63 1.63 0 0 1-1.625 1.625H7.825A1.63 1.63 0 0 1 6.2 23.175V7.825c0-.894.73-1.625 1.625-1.625zm12.07 6.713-1.348 1.334-2.093-2.068v10.254h-1.908V12.178l-2.093 2.069-1.35-1.334L15.5 8.567l4.396 4.346z"
                    className="svg-for-menu"
                  />
                </g>
              </svg>
              <p className="drop-text">Drag and drop files</p>
              <p> or</p>
              <label for="drag-file" className="pri-button">Choose a file</label>
              {/* <input id = "drag-file" type="file" onChange={this.handleChange}></input> */}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    VideoData: state.tutorials.VideoData,
  };
};

const mapDispatchToProps = {
  postTutorialDocumentAction: postTutorialDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Draganddrop);
