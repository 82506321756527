import React from "react";
import ProfileTabs from "./ProfileTabs";
import { useNavigate, useParams } from "react-router";


export default function Profile() {
  const {index} =useParams()
  const navigate = useNavigate()
  
  const handleNavigation = (value) => { 
    console.log(value)
    if(value === 0)
    {
      navigate(`/profile/userinfo`)
    }
    if(value === 2)
    {
      navigate(`/profile/certificates`)
    }
    if(value === 4)
    {
      navigate(`/profile/analytics`)
    }
  }

  return <div>

    <ProfileTabs index={index} navigate={handleNavigation}></ProfileTabs>
   
    
  </div>;
}
