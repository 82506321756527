import actions from "../actions/actionTypes";

let initialState = {
  categories: [],
  playlistCategories: [],
  loaded: false,
};

export default (state = initialState, action) => {
  console.log(action)
  console.log(state)
  switch (action.type) {
    case actions.FETCH_CATEGORY_SUCCEEDED: {
      return {
        ...state,
        categories: action.payload,
        loaded: true,
      };
    }
    case actions.FETCH_PLAYLIST_CATEGORY_SUCCEEDED: {
      return {
        ...state,
        playlistCategories: action.payload,
        loaded: true,
      };
    }
    default: 
      return state 
}}