import React from "react";
import Slider from "react-slick";
import Moment from "moment";
import { Link } from "react-router-dom";

const Videoslider = (props) => {
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: false,
    // nextArrow: '.next_arrow_img',
    // prevArrow: '.previous_arrow_img',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: 'unslick'
      },
    ],
  };

  return (
    <Slider {...settings}>
      {props.props?.map((RelatedVideo, idx) => {
        return (
          <div className="lms-card-w" key={idx}>
            <div className="col-11 col-xs-12">
              <Link to={"/videos/" + RelatedVideo.Id} >
                <div className="card-img">
                  <img
                    src={RelatedVideo.ThumbnailUrl}
                    alt="ThumbnailUrl"></img>
                </div>
                <div className="card-info lms-flex-between">
                  <div className="card-title card-title-w res-catg-marg">{RelatedVideo.CategoryName}</div>
                  <div className="card-date lms-carddate">
                    {Moment(RelatedVideo.CreatedDate).format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="card-heading card-heading-m">
                  {RelatedVideo.Title}
                </div>
                <div className="card-para res-card-desc">{RelatedVideo.Description}</div>
              </Link>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
export default Videoslider;
