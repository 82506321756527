import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import React from "react";

export default class Videoprogressbar extends React.Component {
  constructor(props)
  {
    super(props)
  }

  render() { 
    
    return(
      <Box sx={{ width: '100%' }}>
      <LinearProgress className="progress-bg" variant= 'determinate' value={sessionStorage.getItem('percentage')}/>
    </Box>
)
  }
}



