import axios from "axios";
import store from "../store/configureStore";
import Cookies from "js-cookie";
import { accessToken } from "../components/Common/Constants";

export async function getData(url) {
    var token = Cookies.get(accessToken)
    return axios.get(url, { withCredentials: true,  headers: {
        'Authorization': `bearer ${token}`
      }});
}

export async function postData(url, content) {
  var token = Cookies.get(accessToken)

    return axios.post(url, content, { withCredentials: true,  headers: {
        'Authorization': `bearer ${token}`
      } }).catch(err => console.log(err));
}

export async function putData(url, content) {
  var token = Cookies.get(accessToken)

    return axios.put(url, content, { withCredentials: true,  headers: {
        'Authorization': `bearer ${token}`
      } }).catch(err => console.log(err));
}

export async function deleteData(url, content) {
  var token = Cookies.get(accessToken)

    return axios.delete(url, { withCredentials: true,  headers: {
        'Authorization': `bearer ${token}`
      } }).catch(err => console.log(err));
}