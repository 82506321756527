import React from 'react'

export default function TenantMapping(props) {

    const removeWhiteSpace = (name) => {
        console.log(name)
        return name.replace(/ /g, "")
    }

    var data = {
        tenantId: props.ogranisationId,
        tenantName: props.organisationName,
        tenantRouteName: removeWhiteSpace(props.organisationName),
        organisationUniqueName: props.organisationUniqueName,
        clientId: props.clientId,
    }
    return data
}
