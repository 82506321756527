import actions from "../../../../store/actions/actionTypes";
import { call, delay, put } from "redux-saga/effects";
import API from "../../utils/API";

export function* postTutorialVideoSaga(action) {
  try {
    const response = yield call(API.postTutorialVideo, action.payload);

    const VideoData = response.data;
    const { name } = action.payload.file;
    // to get filenam and extension
    const index = name.lastIndexOf(".") + 1;
    const Extension = name.substring(index, name.length) || name;
    const VideoName = name.substring(0, index - 1);

    yield put({
      type: actions.POST_TUTORIAL_VIDEO_SUCCEEDED,
      payload: { VideoData, VideoName, Extension },
    });
  } catch (error) { }
}

export function* videoUploadCheckSaga(action) {
  try {
    yield put({
      type: actions.VIDEO_UPLOAD_CHECK_SUCCEEDED,
    });
  } catch (error) { }
}

export function* videoUploadtrueSaga(action) {
  try {
    yield put({
      type: actions.VIDEO_UPLOADED_SUCCESSFULLY,
    });
  } catch (error) { }
}

export function* postTutorialDocumentSaga(action) {
  try {
    const response = yield call(API.postTutorialDocument, action.payload);
    const DocumentData = response.data;
    console.log(action)
    const { FileName } = action.payload;
    const index = FileName.lastIndexOf(".") + 1;
    const Extension = FileName.substring(index, FileName.length) || FileName;
    const DocumentName = FileName.substring(0, index - 1);

    yield put({
      type: actions.CREATE_TUTORIAL_DOCUMENT_SUCCEEDED,
      payload: { DocumentData, Extension, DocumentName },
    });
  } catch (error) { console.log(error) }
}

export function* updateTutorialVideoSaga(action) {
  try {
    yield call(API.updateTutorial, action.payload);
    // yield put({
    //   type: actions.UPDATE_TUTORIAL_VIDEO_SUCCEEDED,
    //   DocumentData: DocumentData,
    // });
  } catch (error) { }
}

export function* updateDocumentSaga(action) {
  try {
    yield call(API.updateDocument, action.payload);
    // yield put({
    //   type: actions.UPDATE_TUTORIAL_VIDEO_SUCCEEDED,
    //   DocumentData: DocumentData,
    // });
  } catch (error) { }
}

export function* fetchAdminVideoSaga(action) {
  try {
    const response = yield call(API.fetchAdminVideo, action.payload);

    yield put({
      type: actions.FETCH_ADMIN_VIDEO_SUCCEEDED,
      CollectionData: response.data,
    });
  } catch (error) { }
}

export function* fetchAdminCourseSaga(action) {
  try {
    const response = yield call(API.fetchAdminCourse, action.payload);

    yield put({
      type: actions.FETCH_ADMIN_COURSE_SUCCEEDED,
      CollectionData: response.data,
    });
  } catch (error) { }
}

export function* roleSagas(action) {
  try {
    const response = yield call(API.fetchRoles, action.payload);
    console.log(response)
    const roles = yield response.data;

    yield put({
      type: actions.FETCH_ROLES_SUCCEEDED,
      roles: roles,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ROLES_FAILED, message: error.message });
  }
}

export function* fetchAdminTutorialLoadMoreSaga(action) {
  try {
    const response = yield call(API.fetchAdminVideo, action.payload);
    const tutorials = yield response.data;
    yield delay(1000);

    if (tutorials != "") {
      yield put({
        type: actions.FETCH_ADMIN_TUTORIALS_LOADMORE_SUCCEEDED,
        CollectionData: response.data,
      });
    }
    else {
      yield put({
        type: actions.FETCH_ADMIN_TUTORIALS_LOADMORE_FINISHED,
        CollectionData: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_ADMIN_TUTORIALS_LOADMORE_FAILED,
      message: error.message
    });
  }
}

export function* fetchAdminCourseLoadMoreSaga(action) {
  try {
    const response = yield call(API.fetchAdminCourse, action.payload);
    const courses = yield response.data;
    yield delay(1000);

    if (courses != "") {
      yield put({
        type: actions.FETCH_ADMIN_COURSES_LOADMORE_SUCCEEDED,
        CollectionData: response.data,
      });
    }
    else {
      yield put({
        type: actions.FETCH_ADMIN_COURSES_LOADMORE_FINISHED,
        CollectionData: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_ADMIN_COURSES_LOADMORE_FAILED,
      message: error.message
    });
  }
}

export function* fetchQuizeSaga(action) {
  console.log(action)
  try {
    const response = yield call(API.getAllQuizes, action.payload);

    yield put({
      type: actions.FETCH_QUIZ_SUCCEEDED,
      quiz: response.data,
    });
  } catch (error) { }
}

export function* fetchQuizLoadMoreSaga(action) {
  try {
    const response = yield call(API.getAllQuizes, action.payload);
    const quiz = yield response.data;
    yield delay(1000);

    if (quiz != "") {
      yield put({
        type: actions.FETCH_QUIZ_LOADMORE_SUCCEEDED,
        quiz: response.data,
      });
    }
    else {
      yield put({
        type: actions.FETCH_QUIZ_LOADMORE_FINISHED,
        quiz: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_QUIZ_LOADMORE_FAILED,
      message: error.message
    });
  }
}

export function* fetchTenantDetailsSaga(action) {
  try {
    const response = yield call(API.getTenant, action.payload);
    yield put({
      type: actions.FETCH_TENANT_DETAILS_SUCCEEDED,
      tenant: response.data,
    });
  } catch (error) {

  }
}

export function* fetchAllGroupSaga(action) {
  try {
    const response = yield call(API.getAllGroup, action.payload);
    yield put({
      type: actions.FETCH_ALL_GROUPS_SUCCEEDED,
      groups: response.data,
    });
  } catch (error) {

  }
}

export function* fetchAllGroupLoadMoreSaga(action) {
  try {
    const response = yield call(API.getAllGroup, action.payload);
    const quiz = yield response.data;
    yield delay(1000);

    if (quiz != "") {
      yield put({
        type: actions.FETCH_ALL_GROUPS_LOADMORE_SUCCEEDED,
        group: response.data,
      });
    }
    else {
      yield put({
        type: actions.FETCH_ALL_GROUPS_LOADMORE_FINISHED,
        group: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_ALL_GROUPS_LOADMORE_FAILED,
      message: error.message
    });
  }
}

export function* fetchAdminSettingsSaga() {
  try {
    const response = yield call(API.getAdminSettings);
    yield put({
      type: actions.FETCH_ADMIN_SETTINGS_SUCCEEDED,
      settings: response.data,
    });
  } catch (error) {

  }
}

export function* fetchQuestionLevelSaga() {
  try {
    const response = yield call(API.getQuestionLevels);
    yield put({
      type: actions.FETCH_QUESTION_LEVELS_SUCCEEDED,
      levels: response.data,
    });
  } catch (error) {

  }
}

export function* fetchQuestionsSaga(action) {
  try {
    const response = yield call(API.getAllQuestions, action.payload);
    yield put({
      type: actions.FETCH_QUESTION_SUCCEEDED,
      questions: response.data,
    });
  } catch (error) {

  }
}

export function* fetchQuestionsLoadmoreSaga(action) {
  try {
    const response = yield call(API.getAllQuestions, action.payload);
    const questions = yield response.data;
    yield delay(1000);

    if (questions != "") {
      yield put({
        type: actions.FETCH_QUESTION_LOADMORE_SUCCEEDED,
        questions: response.data,
      });
    }
    else {
      yield put({
        type: actions.FETCH_QUESTION_LOADMORE_FINISHED,
        questions: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.FETCH_QUESTION_LOADMORE_FAILED,
      message: error.message
    });
  }
}