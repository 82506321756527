import React from "react";
import { saveAs } from 'file-saver'
import download from "../../components/Common/DownloadFile";
import { Alert, Backdrop, Box, Modal } from "@mui/material";
import CertificateShareUser from "./CertificateShareUser";
import { useTranslation } from "react-i18next";
import ErrorTab from "../../components/Common/ErrorTab";


const downloadImage = (imgUrl, filename) => {
  saveAs(imgUrl, `${filename}.${imgUrl.split('.').pop()}`) // Put your image url here.
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "64.58vw",
  height: "37.70vw",
  bgcolor: "background.paper",
  border: 0,
  // boxShadow: 24,
  p: 0,
};


export const CertificatesList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [selectedCertificate, setSelectedCertificate] = React.useState({});
  const [alert, setAlert] = React.useState(
    {
      type: 'success',
      active: false
    }
  );
  const { t } = useTranslation();

  const Certificate_Shared_Successfully = t("Certificate_Shared_Successfully")

  const handleOpen = (val) => {
    setSelectedCertificate(val)
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    // selectedUser.splice(0, selectedUser.length);
  };

  const handleAlert = () => {
    setAlert({
      type: 'success',
      active: true
    })
  }

  React.useEffect(() => {
    if (alert.active) {
      setTimeout(() => {
        setAlert({
          type: 'success',
          active: false
        })
      }, 3000);
    }
  }, [alert.active])

  const handleBackDropClose = () => {
    setAlert({
      type: 'success',
      active: false
    })
  }


  if (props.certificates && props.certificates.length > 0) {
    return (
      <React.Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={alert.active}
          onClick={handleBackDropClose}
        >
          <Alert severity={alert.type} style={{ position: "absolute", top: 20 }}>
            {Certificate_Shared_Successfully}
          </Alert>
        </Backdrop>
        {props.certificates.map((certificate, idx) => {
          return (
            <li>
              <div className="col-11 col-xs-12 cer-card">
                <div className="certificate-img">
                  <img src={certificate.CertificateUrl} alt="certificate" />
                </div>
                <div className="user-actions lms-flex">
                  <div className="download-cer col-8">
                    <button className=" download-btn" onClick={() => { download(certificate.CertificateUrl, certificate.PlaylistTitle + '.' + certificate.CertificateUrl.split('.').pop()) }}>Download Certificate</button>
                  </div>
                  <div className="share-cer" onClick={() => handleOpen(certificate)} >
                    <button className=" cir-btn share-btn"><img src="assets/share-cer-icon.png" alt="share" /></button>
                  </div>

                </div>
              </div>
            </li>
          );
        })}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="admin-share-con">
            <Box sx={style}>
              <div className="admin-share-user">
                <div className="share-title">{t("Share_Certificates_with")}</div>
                <CertificateShareUser close={handleClose} selectedCertificate={selectedCertificate} users={props.users} closeModal={handleClose} alert={handleAlert}></CertificateShareUser>
                {/* <SearchUsersComponent type={"select"} /> */}

                {/* <div className="admin-share-user-right">
                  <div className="share-title ">Selected Users:</div>
                  <SearchUsersComponent type={"selected"} />

                  <div className="lms-flex share-buttons ">
                    <button
                      className="primary-button ter-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="primary-button sec-btn ad-share-btn"
                      disabled={selectedUser.length > 0 ? false : true}
                      onClick={handleShare}
                    >
                      Share
                    </button>
                  </div>
                </div> */}
              </div>
            </Box>
          </div>
        </Modal>
      </React.Fragment>



    );
  }
  else {
    return (
      <div id="no-result-saved-item">
        <h1>{t("Certificate_Error_Heading")}</h1>
      </div>
    )
  }
}
export default CertificatesList