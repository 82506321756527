import actions from "../actions/actionTypes";

let initialState = {
    allGroups: { Count: 0, Groups: [] },
    lazyloader: true,
    loaded: false,
    groupPageNumber: 2
};

const allGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_ALL_GROUPS_SUCCEEDED: {
            return {
                ...state,
                allGroups: { Count: action.groups.Count, Groups: action.groups.Groups },
                loaded: true
            };
        }
        case actions.FETCH_ALL_GROUPS_LOADMORE_START:
            return {
                ...state,
                loaded: true,
            };
        case actions.FETCH_ALL_GROUPS_LOADMORE_SUCCEEDED:
            return {
                ...state,
                allGroups: { Count: action.groups.Count, Groups: [...state.allGroups.Groups, ...action.groups.Groups] },
                lazyloader: true,
                userPageNumber: state.groupPageNumber + 1
            };
        case actions.FETCH_ALL_GROUPS_LOADMORE_FAILED:
            return {
                ...state,
                error: action.payload,
                loaded: false,
            };
        case actions.FETCH_ALL_GROUPS_LOADMORE_FINISHED:
            return {
                ...state,
                lazyloader: action.lazyloader,
            };
        default:
            return state;
    }
}

export default allGroupsReducer;