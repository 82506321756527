import { call, put, delay } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../modules/Admin/utils/API";

export function* fetchOrganisationDetailSaga() {
  try {
    const response = yield call(API.getOrganisationDetails);
    const org = yield response.data;

    yield put({
      type: actions.FETCH_ORGANISATION_DETAILS_SUCCEEDED,
      payload: org,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ORGANISATION_DETAILS_FAILED, message: error.message });
  }
}