import React, { Component } from "react";
import { postTutorialDocument } from "../store/actions/admin";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import DocumentCheck from "../Common/DocumentCheck";
import * as constants from "../Common/Constants";


class Draganddrop extends Component {
  constructor(props) {
    super(props);
    this.onDrop = async (files) => {
      console.log('files', files[0])
      if (!constants.acceptedMime.includes(files[0].type)) {
        props.error('Document format incorrect')
      }
      else {
        const { VideoData } = this.props;
        const { VideoId, FileId } = VideoData;
        this.setState({
          FileName: files[0].name,
          VideoId,
          FileId,
          File: files[0]
        });
        props.document(files[0])
        props.error('')
      }
    };
    this.state = {
      VideoId: "",
      FileId: "",
      FileName: "",
      File: {}
    };
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = (e) => {
    const { VideoData } = this.props;
    const { VideoId, FileId } = VideoData;


    this.setState({
      FileName: e.target.files[0].name,
      VideoId,
      FileId,
      File: e.target.files[0]
    });

    localStorage.setItem('docs', JSON.stringify({
      'lastModified': e.target.files[0].lastModified,
      'lastModifiedDate': e.target.files[0].lastModifiedDate,
      'name': e.target.files[0].name,
      'size': e.target.files[0].size,
      'type': e.target.files[0].type,
      'webkitRelativePath': e.target.files[0].webkitRelativePath
    }))
  };

  componentDidUpdate() {
    const { postTutorialDocumentAction } = this.props;

    if (this.state.FileName) {
      postTutorialDocumentAction(this.state);
      // this.setState({ FileName: "" });
    }


  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        {
          this.state.FileName.length > 0 &&
          <DocumentCheck Extension={this.state.FileName.split('.').pop()} Filename={this.state.FileName.split('.')[0]} CreatedDate={this.state.File.lastModifiedDate} />
        }

        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropfile" })}>
              <input {...getInputProps()} />
              <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className="svg-for-menu">
                <rect width="31" height="31" rx="8.448" className="svg-for-menu" />
                <rect x="8.91211" y="7.36243" width="12.4" height="16.275" fill="white" />
                <path className="svg-for-menu" fill-rule="evenodd" clip-rule="evenodd" d="M7.82408 6.19995C12.9407 6.19995 18.0578 6.19995 23.1744 6.19995C24.0683 6.19995 24.7992 6.9309 24.7992 7.82481C24.7992 12.9415 24.7992 18.0585 24.7992 23.1751C24.7992 24.069 24.0683 24.8 23.1744 24.8C18.0577 24.8 12.9407 24.8 7.82408 24.8C6.93017 24.8 6.19922 24.069 6.19922 23.1751C6.19922 18.0585 6.19922 12.9414 6.19922 7.82481C6.19922 6.9309 6.93017 6.19995 7.82408 6.19995ZM19.8951 12.9127C19.4453 13.3573 18.9956 13.8022 18.5458 14.2468C17.8484 13.5574 17.1506 12.868 16.4532 12.1786C16.4532 15.5964 16.4532 19.0147 16.4532 22.4326C15.817 22.4326 15.1811 22.4326 14.5448 22.4326C14.5448 19.0143 14.5448 15.5964 14.5448 12.1782C13.8474 12.8676 13.1496 13.5574 12.4522 14.2468C12.0024 13.8022 11.5527 13.3573 11.1029 12.9127C12.5684 11.464 14.0335 10.0157 15.499 8.56697C16.9645 10.0157 18.4296 11.464 19.895 12.9127H19.8951Z" />
              </svg>
              <p className="drop-text">Drag and drop files</p>
              <p className="drop-text"> or</p>
              <label for="drag-file" className="pri-button">Choose a file</label>
              {/* <input id = "drag-file" type="file" onChange={this.handleChange}></input> */}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    VideoData: state.tutorials.VideoData,
  };
};

const mapDispatchToProps = {
  postTutorialDocumentAction: postTutorialDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Draganddrop);
