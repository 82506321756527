import { BlobServiceClient } from '@azure/storage-blob';
import { useSelector } from 'react-redux';
import store from '../../../store/configureStore';
import { cdnPath } from './Constants';

export default async function Uploadwithsas(file, dataId, sasUrl, path) {

  try {
    const blobServiceClient = new BlobServiceClient(sasUrl);
    const containerClient = await blobServiceClient.getContainerClient(cdnPath);
    const blobOptions = { blobHTTPHeaders: { blobContentType:file.type } };
    const blockBlobClient = containerClient.getBlockBlobClient(`${path}/${dataId}.${file.name.split('.').pop()}`);
    var response = await blockBlobClient.uploadData(file, blobOptions)
    return response._response
  }
  catch (e) {
   console.log(e)
  }
}