import { call, put, delay } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../utils/API";

export function* fetchCoursesSaga() {
  try {
    const response = yield call(API.fetchCourses);
    console.log(response)
    const courses = yield response.data;

    yield put({
      type: actions.FETCH_COURSES_SUCCEEDED,
      courses: courses,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_COURSES_FAILED, message: error.message });
  }
}

export function* fetchSavedCoursesSaga(action) {
  try {
    console.log(action)
    const response = yield call(API.fetchSavedCourses, action.payload);
    console.log(response)
    const savedCourses = yield response.data;

    yield put({
      type: actions.FETCH_SAVED_COURSES_SUCCEEDED,
      savedCourses: savedCourses,
    });
  } catch (error) {
    console.log(error)
    yield put({ type: actions.FETCH_SAVED_COURSES_FAILED, message: error.message });
  }
}

export function* fetchCoursesDetailSaga(action) {
  try {
    const response = yield call(API.fetchCoursesDetail, action.id);
    const coursesDetail = yield response.data;

    yield put({
      type: actions.FETCH_COURSES_DETAIL_SUCCEEDED,
      coursesDetail: coursesDetail,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_COURSES_DETAIL_FAILED,
      message: error.message,
    })
  }
}

export function* filterCoursesSaga(action) {
  try {
    const response = yield call(API.filterCourses, action.payload);
    const courses = yield response.data;
    yield put({
      type: actions.FILTER_COURSES_SUCCEEDED,
      courses: courses,
    });
  } catch (error) {
    yield put({
      type: actions.FILTER_TUTORIALS_FAILED,
      message: error.message,
    })
  }
}

export function* sortCoursesSaga(action) {
  try {
    const response = yield call(API.sortCourses, action.payload);
    const courses = yield response.data;

    yield put({
      type: actions.SORT_COURSES_SUCCEEDED,
      courses: courses,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.SORT_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchInprogressCoursesSaga(action) {
  try {
    const response = yield call(API.getInprogressCourses, action.id);
    const inprogressCourses = yield response.data;

    yield put({
      type: actions.FETCH_INPROGRESS_COURSES_SUCCEEDED,
      inprogressCourses: inprogressCourses,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_INPROGRESS_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchCompletedCoursesSaga(action) {
  try {
    const response = yield call(API.getCompletedCourses, action.id);
    const completedCourses = yield response.data;

    yield put({
      type: actions.FETCH_COMPLETED_COURSES_SUCCEEDED,
      completedCourses: completedCourses,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_COMPLETED_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchCertificatesSaga(action) {
  try {
    const response = yield call(API.getUserCertificates, action.id);
    const certificates = yield response.data;

    yield put({
      type: actions.FETCH_CERTIFICATES_SUCCEEDED,
      certificates: certificates,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_CERTIFICATES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchBadgesSaga(action) {
  try {
    const response = yield call(API.getUserBadges, action.id);
    const badges = yield response.data;

    yield put({
      type: actions.FETCH_BADGES_SUCCEEDED,
      badges: badges,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_BADGES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchSharedByMeCoursesSaga(action) {
  try {
    const response = yield call(API.getSharedByMeCourses, action.payload);
    const sharedbymecourses = yield response.data;

    yield put({
      type: actions.FETCH_SHAREDBYME_COURSES_SUCCEEDED,
      sharedbymecourses: sharedbymecourses,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_SHAREDBYME_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchSharedWithMeCoursesSaga(action) {
  try {
    const response = yield call(API.getSharedWithMeCourses, action.payload);
    const sharedwithmecouses = yield response.data;

    yield put({
      type: actions.FETCH_SHAREDWITHME_COURSES_SUCCEEDED,
      sharedwithmecouses: sharedwithmecouses,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: actions.FETCH_SHAREDWITHME_COURSES_FAILED,
      message: error.message,
    })
  }
}

export function* fetchCoursesLoadMoreSaga(action) {
  try {
    const response = yield call(API.fetchCoursesLoadMore, action.payload);
    const courses = yield response.data;
    yield delay(1000);

    if (courses != "") {
      yield put({
        type: actions.FETCH_COURSES_LOADMORE_SUCCEEDED,
        courses: courses,
      });
    }
    else {
      yield put({
        type: actions.FETCH_COURSES_LOADMORE_FINISHED,
        lazyloader: false,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_COURSES_LOADMORE_FAILED,
      message: error.message
    });
  }
}
export function* fetchSavedCourseLoadMoreSaga(action) {
  try {
    const response = yield call(API.fetchSavedCourses, action.payload);
    const savedCourses = yield response.data;
    yield delay(1000);

    if (savedCourses != "") {
      yield put({
        type: actions.FETCH_SAVED_COURSES_LOADMORE_SUCCEEDED,
        savedCourses: savedCourses,
      });
    }
    else {
      yield put({
        type: actions.FETCH_SAVED_COURSES_LOADMORE_FINISHED,
        lazyloader: false,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_SAVED_COURSES_LOADMORE_FAILED,
      message: error.message
    });
  }
}
export function* fetchSharedByMeCourseLoadMoreSaga(action) {
  try {
    const response = yield call(API.getSharedByMeCourses, action.payload);
    const sharedbymecourses = yield response.data;
    yield delay(1000);

    if (sharedbymecourses != "") {
      yield put({
        type: actions.FETCH_SHAREDBYME_COURSES_LOADMORE_SUCCEEDED,
        sharedbymecourses: sharedbymecourses,
      });
    }
    else {
      yield put({
        type: actions.FETCH_SHAREDBYME_COURSES_LOADMORE_FINISHED,
        lazyloader: false,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_SHAREDBYME_COURSES_LOADMORE_FAILED,
      message: error.message
    });
  }
}
export function* fetchSharedWithMeCourseLoadMoreSaga(action) {
  try {
    const response = yield call(API.getSharedWithMeCourses, action.payload);
    const sharedwithmecouses = yield response.data;
    yield delay(1000);

    if (sharedwithmecouses != "") {
      yield put({
        type: actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_SUCCEEDED,
        sharedwithmecouses: sharedwithmecouses,
      });
    }
    else {
      yield put({
        type: actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_FINISHED,
        lazyloader: false,
      });
    }

  } catch (error) {
    yield put({
      type: action.FETCH_SHAREDWITHME_COURSES_LOADMORE_FAILED,
      message: error.message
    });
  }
}

export function* fetchCourseReportSaga() {
  try {
    const response = yield call(API.getCourseChartPercentage);
    const data = yield response.data;
    yield put({
      type: actions.FETCH_COURSE_REPORT_SUCCEEDED,
      payload: data
    });

  } catch (error) {
    yield put({
      type: actions.FETCH_COURSE_REPORT_FAILED,
      message: error.message
    });
  }
}

