import React from 'react'
import CompletedCoursesList from '../../components/CoursesList/CompletedCoursesList';
import InProgressCoursesList from '../../components/CoursesList/InProgressCoursesList'
import { useTranslation } from 'react-i18next';

function UserCoursesDetails(props) {
  const [viewAllProgressCourse, setviewAllInProgressCourse] = React.useState(false);
  const [viewAllCompletedCourse, setviewAllCompletedCourse] = React.useState(false);

  const {t} = useTranslation()
  const setViewInProgressCourse = () => {
    setviewAllInProgressCourse(!viewAllProgressCourse);
  }

  const setViewInCompletedCourse = () => {
    setviewAllCompletedCourse(!viewAllCompletedCourse);
  }

  console.log(props)
  return (
    <div>
      {
        !viewAllCompletedCourse &&
        <div className="user-courses-con">
          {/* <div>
            <h6>Ongoing Courses</h6>
          </div> */}
          <div className="view-all-content">
            {(!viewAllProgressCourse) ? (
              props.props.InprogressCourses.length > 5 ?
                (<div className="lms-flex-between">
                  <span className="view-heading">Videos</span>
                  <h6 className="view-all" onClick={setViewInProgressCourse}>
                    VIEW ALL{" "}
                    <span>
                      <svg width="8" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87341 4.72559L0.734858 0.113083C0.5665 -0.0379512 0.293923 -0.0376973 0.125846 0.113865C-0.0421 0.265407 -0.0416661 0.510894 0.126714 0.662046L4.9594 5.00002L0.126541 9.33797C-0.041818 9.48914 -0.0422519 9.73447 0.125673 9.88604C0.209928 9.96201 0.320308 10 0.430689 10C0.540787 10 0.650733 9.96227 0.734837 9.88682L5.87341 5.27443C5.9545 5.20181 6 5.10299 6 5.00002C6 4.89705 5.95437 4.79834 5.87341 4.72559Z" fill="#062349" />
                      </svg>
                    </span>
                  </h6>
                </div>) : (<span className="view-heading">{t("Ongoing_Courses")}</span>)
            ) : (
              <div onClick={setViewInProgressCourse}>
                <span className="view-heading-backarrow">{"<"}</span>{" "}
                <span className="view-heading-back">{` ${t("Ongoing_Courses")}`}</span>
              </div>
            )}
          </div>
          <div className="courses-details">
            <ul className="card-grid">
              <InProgressCoursesList props={viewAllProgressCourse ? props.props.InprogressCourses : props.props.InprogressCourses.slice(0, 5)}></InProgressCoursesList>
            </ul>
          </div>

        </div>
      }
      {
        !viewAllProgressCourse &&
        <div className={'user-courses-con m-0'}>
          <div className="view-all-content">
            {(!viewAllCompletedCourse) ? (
              props.props.InprogressCourses.length > 5 ?
                (<div className="lms-flex-between">
                  <span className="view-heading">Videos</span>
                  <h6 className="view-all" onClick={setViewInCompletedCourse}>
                    VIEW ALL{" "}
                    <span>
                      <svg width="8" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.87341 4.72559L0.734858 0.113083C0.5665 -0.0379512 0.293923 -0.0376973 0.125846 0.113865C-0.0421 0.265407 -0.0416661 0.510894 0.126714 0.662046L4.9594 5.00002L0.126541 9.33797C-0.041818 9.48914 -0.0422519 9.73447 0.125673 9.88604C0.209928 9.96201 0.320308 10 0.430689 10C0.540787 10 0.650733 9.96227 0.734837 9.88682L5.87341 5.27443C5.9545 5.20181 6 5.10299 6 5.00002C6 4.89705 5.95437 4.79834 5.87341 4.72559Z" fill="#062349" />
                      </svg>
                    </span>
                  </h6>
                </div>) : (<span className="view-heading">{t("Courses_Completed")}</span>)
            ) : (
              <div onClick={setViewInCompletedCourse}>
                <span className="view-heading-backarrow">{"<"}</span>{" "}
                <span className="view-heading-back">{` ${t("Courses_Completed")}`}</span>
              </div>
            )}
          </div>
          <div className="courses-details">
            <ul className="row lms-flex">
              <CompletedCoursesList props={viewAllCompletedCourse ? props.props.CompletedCourses : props.props.CompletedCourses.slice(0, 5)}></CompletedCoursesList>
            </ul>
          </div>

        </div>}
    </div>
  );
}
export default UserCoursesDetails;