import React from "react";
import CreateUploadCourse from "../CreateCourse/UploadCourse";
import VerticalTimeline from "./VerticalTimeline";

function CreateCourseFirst(props) {
  return (
    <div>
      <div className="video-tab-con lms-flex-between">
        <div className="video-tab-con-left">
          <CreateUploadCourse selectedVideos={props.selectedVideos} />
        </div>
        <div className="video-tab-con-right">
          <VerticalTimeline
            selectedVideos={props.selectedVideos}
            courseType={props.courseType}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateCourseFirst;
