// import React from "react";

// function CourseFirst() {
//   return <div>CourseFirst</div>;
// }

// export default CourseFirst;
//import { Alert } from "@mui/material";
import { any } from "prop-types";
import React from "react";
import UploadCourse from "./UploadCourse";
import VerticalTimeline from "./VerticalTimeline";

function CourseFirst() {
  const [selectedVideo, setSelectedVideo] = React.useState({})
  const [getThumbnail, setGetThumbnail] = React.useState('')
  const [successResposnse, setSuccessResposnse] = React.useState({
    response: any,
    videoId: any
  })
  const [getFiles, setGetFiles] = React.useState([])
const [uploadSucess, setUploadSucess] = React.useState(false)


  const handleSuceessResponse = (e) => {
    console.log(e)
    setSuccessResposnse(e)
  }
  return (
    <div>
      <div className="video-tab-con lms-flex-between">
        <div className="video-tab-con-left">
          <UploadCourse selectVideo={(e) => setSelectedVideo(e)} successResponse={successResposnse} getFiles = {(e) => {setGetFiles(e)}}  getThumbnail = {(e) => {setGetThumbnail(e)}} uploadSuccess= {(e) => setUploadSucess(e)} />
        </div>
        <div className="video-tab-con-right">{ 
        uploadSucess &&
        <VerticalTimeline selectVideo={selectedVideo} thumbnail = {getThumbnail} suceessResponse={(e) => handleSuceessResponse(e)} files = {getFiles} isVideoUploaded={uploadSucess} />
        }</div>
      </div>
    </div>
  );
}

export default CourseFirst;
