import actions from "../actions/actionTypes";


let initialState = {
    bluebar: true,
    course:{
      thumbnail:'',
      percentage: '',
      id: '',
    }
  };
  
  export default (state = initialState, action) => {
    console.log('bluebarAction', action)
    console.log(state)
    switch (action.type) {
      case actions.HANDLE_BLUEBAR_SUCCEEDED: {
        return {
          ...state,
          bluebar: action.payload,
        };
      }
      case actions.HANDLE_BLUEBAR_COURSE_SUCCEEDED: {
        return {
          ...state,
          course: {
            thumbnail: action.payload.ThumbnailUrl,
            percentage: action.payload.HistoryViewModel.SeekTime,
            id: action.payload.CourseId,
          }
        }
      }
      
     
      default: 
        return state 
  }}