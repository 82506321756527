import actions from "./actionTypes";

export const fetchCourses = () => {
  return {
    type: actions.FETCH_COURSES_START,
  };
};

export const fetchSavedCourses = (payload) => {
  return {
    type: actions.FETCH_SAVED_COURSES_START,
    payload
  };
};

export const fetchCoursesDetail = (id) => {
  return {
    type: actions.FETCH_COURSES_DETAIL_START,
    id,
  };
};

export const filterCourses = (payload) => {
  return {
    type: actions.FILTER_COURSES_START,
    payload,
  };
};

export const sortCourses = (payload) => {
  return {
    type: actions.SORT_COURSES_START,
    payload,
  };
};

export const fetchInprogressCourses = (payload) => {
  return {
    type: actions.FETCH_INPROGRESS_COURSES_START,
    payload
  };
};

export const fetchCompletedCourses = (payload) => {
  return {
    type: actions.FETCH_COMPLETED_COURSES_START,
    payload
  };
};

export const fetchCertificates = (payload) => {
  return {
    type: actions.FETCH_CERTIFICATES_START,
    payload
  };
};

export const fetchBadges = (payload) => {
  return {
    type: actions.FETCH_BADGES_START,
    payload
  };
};

export const fetchSharedByMeCourses = (payload) => {
  return {
    type: actions.FETCH_SHAREDBYME_COURSES_START,
    payload
  };
};

export const fetchSharedWithMeCourses = (payload) => {
  return {
    type: actions.FETCH_SHAREDWITHME_COURSES_START,
    payload
  };
};
export const fetchCoursesLoadmore = (payload) => ({
  type: actions.FETCH_COURSES_LOADMORE_START,
  payload,
});

export const fetchCoursesLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_COURSES_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchCoursesLoadMoreFailed = (payload) => ({
  type: actions.FETCH_COURSES_LOADMORE_FAILED,
  payload,
});
export const fetchSavedCourseLoadMore = (payload) => {
  return {
    type: actions.FETCH_SAVED_COURSES_LOADMORE_START,
    payload
  };
};
export const fetchSavedCourseLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_SAVED_COURSES_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchSavedCourseLoadMoreFailed = (payload) => ({
  type: actions.FETCH_SAVED_COURSES_LOADMORE_FAILED,
  payload,
});
export const fetchSharedByMeCourseLoadMore = (payload) => {
  return {
    type: actions.FETCH_SHAREDBYME_COURSES_LOADMORE_START,
    payload
  };
};
export const fetchSharedByMeCourseLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_SHAREDBYME_COURSES_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchSharedByMeCourseLoadMoreFailed = (payload) => ({
  type: actions.FETCH_SHAREDBYME_COURSES_LOADMORE_FAILED,
  payload,
});
export const fetchSharedWithMeCourseLoadMore = (payload) => {
  return {
    type: actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_START,
    payload
  };
};
export const fetchSharedWithMeCourseLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchSharedWithMeCourseLoadMoreFailed = (payload) => ({
  type: actions.FETCH_SHAREDWITHME_COURSES_LOADMORE_FAILED,
  payload,
});

export const fetchCourseReport = () => ({
  type: actions.FETCH_COURSE_REPORT_START,
});