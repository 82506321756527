import { call, put } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../utils/API";

export function* handleBlueBarSaga(action) {
    try {
        console.log("in sagas", action);
        yield put({
            type: actions.HANDLE_BLUEBAR_SUCCEEDED,
            payload: action.payload
        });
    } catch (error) {
        console.log(error)

    }
}

export function* handleBlueBarCourseSaga(action) {
    try {
        const response = yield call(API.getLastAccessedSavedCourse, action.payload);
        const data = yield response.data;
        yield put({
            type: actions.HANDLE_BLUEBAR_COURSE_SUCCEEDED,
            payload: data
        });
    } catch (error) {
        console.log(error)

    }
}