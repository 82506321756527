import React from "react";
import UploadVideo from "./UploadVideo";
import VerticalTimeline from "./VerticalTimeline";
import { Alert, Backdrop } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";

function VideoFirst(props) {
  const [getFiles, setGetFiles] = React.useState([])
  const [getThumbnail, setGetThumbnail] = React.useState('')
  const [videolength, setVideoLength] = React.useState(0)
  const [isUploadDone, setIsUploadDone] = React.useState(false)
  const [videoUploaded, setVideoUploaded] = React.useState({})
  const [thumbnailUploaded, setThumbnailUploaded] = React.useState({})
  const [documnetUploaded, setDocumentUploaded] = React.useState({})
  const [backdropOpenVideo, setbackDropOpenVideo] = React.useState(false);
  const [backdropOpenThumbanil, setbackDropOpenThumbnail] = React.useState(false);
  const [backdropOpenDocument, setbackDropOpenDocument] = React.useState(false);
  const { t } = useTranslation();

  const Video_Upload_Progress = t("Video_Upload_Progress")



  const handleBackDropClose = () => {
    setbackDropOpenVideo(false);
    setbackDropOpenThumbnail(false);
    setbackDropOpenDocument(false);
  };

  const handleIsVideoUploadDone = (e) => {
    setIsUploadDone(e)
    setbackDropOpenVideo(e);
  };

  const handleVideoUpload = (e) => {
    setVideoUploaded(e)
    sessionStorage.removeItem('percentage')
  };

  const handleThumbanilUpload = (e) => {
    setThumbnailUploaded(e)
  };

  const handleDocumentUpload = (e) => {
    localStorage.removeItem('docs')
    setDocumentUploaded(e)
  };

  React.useEffect(() => {
    setTimeout(() => {
      handleBackDropClose();
    }, 4000);
  }, [backdropOpenVideo])


  return (
    <div>
      <div className="video-tab-con lms-flex-between">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropOpenVideo}
          onClick={handleBackDropClose}
        >
          <Alert severity='warning' style={{ position: "absolute", top: 20 }}>
            {Video_Upload_Progress}
          </Alert>
        </Backdrop>
        <div className="video-tab-con-left">
          <UploadVideo getFiles={(e) => { setGetFiles(e) }} getThumbnail={(e) => { setGetThumbnail(e) }} isUploadDone={isUploadDone} videolength={(e) => { setVideoLength(e) }} />

        </div>
        <div className="video-tab-con-right">
          <VerticalTimeline
            files={getFiles}
            thumbnail={getThumbnail}
            isUploadDone={(e) => handleIsVideoUploadDone(e)}
            videoUploaded={(e) => handleVideoUpload(e)}
            thumbnailUploaded={(e) => handleThumbanilUpload(e)}
            documentUploaded={(e) => handleDocumentUpload(e)}
            videolength={videolength} />
        </div>
      </div>
    </div>
  );
}



export default VideoFirst;



