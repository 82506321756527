import TenantMapping from "../../modules/Admin/Common/TenantMapping";
import actions from "../actions/actionTypes";

let initialState = {
  tenant: {},
  settings: [],
  loaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TENANT_DETAILS_SUCCEEDED: {
      return {
        ...state,
        tenant: TenantMapping(action.tenant),
        loaded: true
      };
    }
    case actions.FETCH_ADMIN_SETTINGS_SUCCEEDED: {
      return {
        ...state,
        settings: action.settings,
      };
    }
    default:
      return state
  }
}