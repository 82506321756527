import actions from "./actionTypes";

export const fetchTutorials = () => {
  return {
    type: actions.FETCH_TUTORIALS_START,
  };
};

export const fetchSavedTutorials = (payload) => {
  return {
    type: actions.FETCH_SAVED_TUTORIALS_START,
    payload
  };
};

export const filterTutorials = (payload) => ({
  type: actions.FILTER_TUTORIALS_START,
  payload,
});

export const sortTutorials = (payload) => ({
  type: actions.SORT_TUTORIALS_START,
  payload,
});

// search functionality

export const searchTutorials = (payload) => ({
  type: actions.SEARCH_TUTORIALS_START,
  payload,
});

export const fetchTutorialsLoadmore = (payload) => ({
  type: actions.FETCH_TUTORIALS_LOADMORE_START,
  payload,
});

export const fetchTutorialsLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_TUTORIALS_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchTutorialsLoadMoreFailed = (payload) => ({
  type: actions.FETCH_TUTORIALS_LOADMORE_FAILED,
  payload,
});

export const fetchTutorialsDetail = (id) => {
  return {
    type: actions.FETCH_TUTORIALS_DETAIL_START,
    id,
  };
};

export const fetchTutorialsDetailSucess = (id) => ({
  type: actions.FETCH_TUTORIALS_DETAIL_SUCCESS,
  id,
});

export const fetchSharedByMeVideos = (payload) => {
  return {
    type: actions.FETCH_SHAREDBYME_VIDEOS_START,
    payload
  };
};

export const fetchSharedWithMeVideos = (payload) => {
  return {
    type: actions.FETCH_SHAREDWITHME_VIDEOS_START,
    payload
  };
};

export const fetchSavedVideosLoadMore = (payload) => {
  return {
    type: actions.FETCH_SAVED_LOADMORE_TUTORIALS_START,
    payload
  };
};
export const fetchSavedVideosLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_SAVED_LOADMORE_TUTORIALS_SUCCEEDED,
  payload,
});

export const fetchSavedVideosLoadMoreFailed = (payload) => ({
  type: actions.FETCH_SAVED_LOADMORE_TUTORIALS_FAILED,
  payload,
});
export const fetchSharedBymeVideosLoadMore = (payload) => {
  return {
    type: actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_START,
    payload
  };
};
export const fetchSharedBymeVideosLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchSharedBymeVideosLoadMoreFailed = (payload) => ({
  type: actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_FAILED,
  payload,
});
export const fetchSharedWithmeVideosLoadMore = (payload) => {
  return {
    type: actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_START,
    payload
  };
};
export const fetchSharedWithmeVideosLoadMoreSucceed = (payload) => ({
  type: actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_SUCCEEDED,
  payload,
});

export const fetchSharedWithmeVideosLoadMoreFailed = (payload) => ({
  type: actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FAILED,
  payload,
});

export const resetTutorialDetails = () => ({
  type: actions.RESET_TUTORIALS_DETAILS_START,
});