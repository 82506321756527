import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TranscriptLabel from "../TranslatedComponents/TranscriptLabel"
import DocumentsLabel from "../TranslatedComponents/DocumentsLabel"
import DescriptionLabel from "../TranslatedComponents/DescriptionLabel"
import NotesLabel from "../TranslatedComponents/NotesLabel"
import API from "../../utils/API";
import { connect, useSelector } from "react-redux";
import store from "../../store/configureStore";
import { fetchTutorialsDetail } from "../../store/actions/tutorials";
import { saveAs } from "file-saver";
import RichTextEditorNotes from "../Common/RichTextEditorNotes"
import DocumentCheck from "../../modules/Admin/Common/DocumentCheck";
import download from "../Common/DownloadFile";
import { extension } from "../../modules/Admin/Common/Constants";
import { useTranslation } from "react-i18next";

function Detailtabs(props) {
  const [content, setContent] = React.useState('');
  const [notes, setNotes] = React.useState({});

  const tabsMargin = `tabsStyling ${document.body.dir === "ltr" ? "tabs-r-margin" : "tabs-l-margin"}`
  const { t } = useTranslation()

  const No_Data_available = t("No_Data_available")
  const Save = t("Save")
  const Notes_Saved_Success = t("Notes_Saved_Success")



  const userId = useSelector(() => store.getState().profileData.id);

  const downloadImage = (url, filename) => {
    saveAs(url, `${filename}.${url.split('.').pop()}`) // Put your image url here.
  }

  React.useEffect(() => {
    API.getNotes({ userId: userId, videoId: props.props.Id }).then(res => setNotes(res.data))
  }, [])



  const handleContent = (data) => {
    setContent(data.trim())
  }

  const handleSaveNotes = () => {
    API.saveNotes(userId, props.props.Id, content).then(res => res.status === 201 && props.success({
      body: Notes_Saved_Success,
      isTrue: true
    })
    ).then(res => { 
      API.getNotes({ userId: userId, videoId: props.props.Id }).then(res => setNotes(res.data))
    })
    // props.fetchTutorialsDetail(props.props.Id)
    // API.getDocument({ userId: userId, videoId: props.props[videoId].Id }).then(res => setDocument(res.data))
  }


  return (
    <div>
      <Tabs>
        <TabList>
          <Tab className={tabsMargin}><DescriptionLabel /></Tab>
          <Tab className={tabsMargin}><DocumentsLabel /></Tab>
          <Tab className={tabsMargin}><TranscriptLabel /></Tab>
          <Tab className={tabsMargin}><NotesLabel /></Tab>
        </TabList>
        <TabPanel>
          <div id="lms-tab-1" className="lms-tabcontent current">
            <p className="p-0">{props.props.Description}</p>
          </div>
        </TabPanel>
        <TabPanel>

          {props.props.Documents.length > 0 ? (
            <div id="lms-tab-2" className="lms-tabcontent">
              <ul className="video-de-docs"
              >
                {props.props.Documents?.map((document, idx) => {
                  return (
                    <li
                      key={idx}
                    >
                      {
                        extension.filter(a => a.exstension.includes(document.Extension.trim())).length !== 0 ?
                          <div onClick={() => { download(document.CdnUrl, document.Title + '.' + document.CdnUrl.split('.').pop()) }} style={{ cursor: 'pointer' }}>
                            <DocumentCheck Extension={document.Extension} Filename={document.Title} CreatedDate={document.CreatedDate} />
                          </div>
                          :
                          <div>{No_Data_available}</div>
                      }
                    </li>
                  );
                }
                )}
              </ul>
            </div>
          ) : (
            <div>{No_Data_available}</div>
          )}
        </TabPanel>
        <TabPanel>
          <div id="lms-tab-3" className="lms-tabcontent">
            <p>{props.props.Transcript ? (
              props.props.Transcript
            ) : (
              <div>{No_Data_available}</div>
            )}</p>
          </div>
        </TabPanel>
        <TabPanel>
          <div id="lms-tab-4" className="lms-tabcontent current">
          <RichTextEditorNotes value={notes.Notes} content={handleContent} />
            {/* <textarea placeholder="Start writing your notes here." type="textarea" rows="20" cols="50" id="Notes">{props.tutorials.Notes}</textarea> */}
            <button className="notes-tab-btn sec-btn savebtn" onClick={handleSaveNotes}>{Save}</button>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state) => ({
  tutorials: state.tutorials.tutorialsDetail,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTutorialsDetail: (id) => dispatch(fetchTutorialsDetail(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Detailtabs);