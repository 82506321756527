import { getData } from "../../../axios/axiosbase";

const getUserData = (payload) => {
    return getData(
      process.env.REACT_APP_FUSION_AUTH_SERVER +
     `/app/me`
    );
  }
  
  
  export default {
    getUserData: getUserData,
  }