import actions from "../actions/actionTypes";

let initialState = {
    organisation: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
      case actions.FETCH_ORGANISATION_DETAILS_SUCCEEDED: {
        return {
          ...state,
          organisation: action.payload, 
        };
      }
      default: 
      return state 
    }
}