import { call, put } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../utils/API";

export function* AllUserSaga(action) {
    try {
      const response = yield call(API.fetchUsers, action.payload);
      console.log(response)
      const users = yield response.data;
  
      yield put({
        type: actions.FETCH_ALL_USERS_SUCCEEDED,
        users: users,
      });
    } catch (error) {
      yield put({ type: actions.FETCH_ALL_USERS_FAILED, message: error.message });
    }
  }

  export function* loadMoreUsersSaga(action) {
    try {
      const response = yield call(API.fetchUsers, action.payload);
      console.log(response)
      const users = yield response.data;
      if(users.Users != ""){
        yield put({
          type: actions.FETCH_USERS_LOADMORE_SUCCEEDED,
          users: users,
        });
      }
      else{
        yield put({
          type: actions.FETCH_USERS_LOADMORE_FINISHED,
          lazyloader: false,
        });
      }
      
    } catch (error) {
      yield put({ type: actions.FETCH_USERS_LOADMORE_FAILED, message: error.message });
    }
  }