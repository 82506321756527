import actions from "../../../../store/actions/actionTypes";

export const postTutorialVideo = (payload) => {
  return {
    type: actions.POST_TUTORIAL_VIDEO_START,
    payload,
  };
};

export const videoUploadCheck = () => {
  return {
    type: actions.VIDEO_UPLOAD_CHECK,
  };
};

export const videoUploadChecktrue = () => {
  return {
    type: actions.VIDEO_UPLOADED_CHECK,
  };
};

export const postTutorialDocument = (payload) => {
  return {
    type: actions.CREATE_TUTORIAL_DOCUMENT_START,
    payload,
  };
};

export const updateTutorialVideo = (payload) => {
  return {
    type: actions.UPDATE_TUTORIAL_VIDEO_START,
    payload,
  };
};

export const updateDocumentVideo = (payload) => {
  return {
    type: actions.UPDATE_DOCUMENT_VIDEO_START,
    payload,
  };
};

export const fetchAdminVideo = (payload) => {
  return {
    type: actions.FETCH_ADMIN_VIDEO_START,
    payload,
  };
};

export const fetchAdminCourse = (payload) => {
  return {
    type: actions.FETCH_ADMIN_COURSE_START,
    payload,
  };
};

export const getAllRoles = (payload) => {
  return {
    type: actions.FETCH_ROLES_START,
    payload
  };
};

export const fetchAdminTutorialsLoadmore = (payload) => ({
  type: actions.FETCH_ADMIN_TUTORIALS_LOADMORE_START,
  payload,
});

export const fetchAdminCourseLoadmore = (payload) => ({
  type: actions.FETCH_ADMIN_COURSES_LOADMORE_START,
  payload,
});

export const fetchQuiz = (payload) => {
  return {
    type: actions.FETCH_QUIZ_START,
    payload,
  };
};

export const fetchQuestions = (payload) => {
  return {
    type: actions.FETCH_QUESTION_START,
    payload,
  };
};

export const fetchQuestionsLoadMore = (payload) => {
  return {
    type: actions.FETCH_QUESTION_LOADMORE_START,
    payload,
  };
};

export const fetchQuestionLevel = () => {
  return {
    type: actions.FETCH_QUESTION_LEVELS_START,
  };
};

export const fetchQuizLoadmore = (payload) => ({
  type: actions.FETCH_QUIZ_LOADMORE_START,
  payload,
});

export const fetchTenantDetailsAction = (payload) => ({
  type: actions.FETCH_TENANT_DETAILS_START,
  payload,
});

export const fetchAllGroups = (payload) => {
  return {
    type: actions.FETCH_ALL_GROUPS_START,
    payload,
  };
};

export const fetchAllGroupsLoadMore = (payload) => {
  return {
    type: actions.FETCH_ALL_GROUPS_LOADMORE_START,
    payload,
  };
};

export const fetchAdminSettings = () => {
  return {
    type: actions.FETCH_ADMIN_SETTINGS_START,
  };
};

