import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Checkbox } from '@mui/material';
import moment from "moment";
import GenerateProfilePic from '../../../components/Common/GenerateProfilePictureIntitals';
import ErrorTab from '../../../components/Common/ErrorTab';
import { Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next'
import { numberFormater, userDelete } from './Constants';


export default function TableLayout(props) {
    const { t } = useTranslation()
    const [selectedUsers, setSelectedUsers] = React.useState([])
    const User_deletd_alert = t("User_deletd_alert")

    React.useEffect(() => {
        props.data ?
            setSelectedUsers(
                props.data.map(d => {
                    return {
                        select: false,
                        Id: d.Id,
                        ProfileImageUrl: d.ProfileImageUrl == '' ? GenerateProfilePic(d.Name) : d.ProfileImageUrl,
                        Name: d.Name,
                        Email: d.Email,
                        RoleId: d.RoleId,
                        IsActive: d.IsActive,
                        SaveCourseCount: numberFormater(d.SaveCourseCount)
                    }
                })
            )
            :
            setSelectedUsers([])
    }, [props.data])

    return (
        <TableContainer>
            {selectedUsers.length > 0 ? <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className='table_cell_design table-text-align' style={{ width: 10 }}><Checkbox className='checkbox' onChange={(e) => {
                            let checked = e.target.checked;
                            setSelectedUsers(
                                selectedUsers.map(d => {
                                    d.select = checked;
                                    return d;
                                })
                            );
                            props.selected(selectedUsers.filter(i => i.select))
                        }} /></TableCell>
                        {
                            props.columns.map((i, index) => { return <TableCell align={i.align} className='table_cell_colour table_cell_design' style={{ width: i.width }} key={index} >{i.headerName}</TableCell> })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedUsers.map((i) => (
                        <TableRow
                            key={i.Id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell className='table-text-align table_cell_design'>
                                <Checkbox className='checkbox' value={i.Id} checked={i.select} onChange={(e) => {
                                    let checked = e.target.checked;
                                    setSelectedUsers(
                                        selectedUsers.map(data => {
                                            if (i.Id === data.Id) {
                                                data.select = checked;
                                            }
                                            return data;
                                        })
                                    );
                                    props.selected(selectedUsers.filter(i => i.select))
                                }} />
                            </TableCell>
                            <TableCell className=' table_cell_design table_cell_colour' >
                                <div className='d-flex align-items-center'>
                                    <div className='width-for-profile'>
                                        <img src={i.ProfileImageUrl} className='profile-avatar' />
                                    </div>
                                    {i.Name}
                                </div>
                            </TableCell>
                            <TableCell align='center' className='table_cell_design' style={{ color: i.IsActive && '#278A42' }} >{i.IsActive ? 'Active' : 'InActive'}</TableCell>
                            <TableCell align='center' className='table_cell_design table_cell_colour'>{i.SaveCourseCount}</TableCell>
                            <TableCell align='center' className='table_cell_design table_cell_colour'>{moment(Date.now()).format("DD/MM/YYYY")}</TableCell>
                            {/* <TableCell align='center' className='table_cell_design table_cell_colour'>Information</TableCell> */}
                            <TableCell className='table_cell_design table_cell_colour'>
                                <div
                                    className="d-flex admin-role justify-content-center align-items-center"
                                    style={{ cursor: "pointer" }}
                                >
                                    <select style={{ border: 'none', outline: 0 }} onChange={(e) => props.changeRole(i, e.target.value)}>
                                        {
                                            props.roles.map((role) => (
                                                <option key={role.Id} value={role.Id} selected={role.Id === i.RoleId ? true : false}>{role.Role}</option>
                                            ))
                                        }

                                    </select>
                                </div>
                            </TableCell>
                            {/* <TableCell align='center' className='table_cell_design table_cell_colour'>
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ cursor: "pointer" }}
                                >
                                    <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <rect class="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" class="edit-icon-svg" />
                                        </svg>
                                </div>
                            </TableCell> */}
                            <TableCell align='center' className='table_cell_design table_cell_colour'>
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ cursor: "pointer" }}
                                >
                                    <Tooltip title={i.IsActive ? "" : User_deletd_alert}>
                                        <svg id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => i.IsActive && props.delete(i, true, userDelete)}>
                                            <rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className="del-stroke" stroke-width="0.9" />
                                            <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white" />
                                            <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                            <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" className="del-svg" />
                                            <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white" />
                                            <path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" />
                                            <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white" />
                                        </svg>
                                    </Tooltip>

                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table> :
                <div id='no-result'>
                <h1>{t("User_Error_Heading")}</h1>
                <p>{t("Error_Body")}</p>
            </div>}
        </TableContainer>)
}
