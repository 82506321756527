import React from "react";
import styled from "styled-components";

const Title = styled.h1`
  letter-spacing: 1.22px;
  color: #062349;
  font-weight: bold;
  font-size: 1.35416vw;
`;

const PageTitle = (props) => {
  return <Title>{props.props}</Title>;
};

export default PageTitle;
