import actions from "../actions/actionTypes";

let initialState = {
    roles: []
};

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_ROLES_SUCCEEDED: {
            console.log(action);
            return {
                ...state,
              roles: action.roles
            };
        }
        case actions.FETCH_ROLES_FAILED: {
            return {
                ...state,
            }
        }
        default:
            return state;
    }
}

export default roleReducer;