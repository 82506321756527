
export const config = {
    clientID: process.env.REACT_APP_AUTH_CLIENTID, // The client ID of your FusionAuth application, e.g., '90ba1caf-c0c1-b30a-af38-3ed438df9fc0'
    serverUrl: process.env.REACT_APP_FUSION_AUTH_SERVER,  // The URL of your server that performs the token exchange
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI, // Where to redirect to your React client after authentication
    // uncomment and set the following to override default server paths
    // loginPath: '/app/login',
    // logoutPath: '/app/logout',
    // registerPath: '/app/register',
    // tokenRefreshPath: '/app/refresh',
    // mePath: '/app/me',

};