import actions from "../actions/actionTypes";
// import actionss from '../../modules/Admin/store/actions/actionTypes';

let initialState = {
  tutorials: { Count: 0, Videos: [] },
  savedTutorials: { Count: 0, Videos: [] },
  sharedbymevideos: { Count: 0, Videos: [] },
  sharedwithmevideos: { Count: 0, Videos: [] },

  tutorialsDetail: [],
  tutorialsDetailLoaded: false,

  loaded: false,

  lazyloader: true,
  savedVideoLazyLoader: true,
  sharedBymeVideoLazyLoader: true,
  sharedWithMeVideoLazyLoader: true,

  VideoData: {},
  isVideoUploaded: false,
  DocumentData: {},
  DocumentName: "",
  DocumentExtension: "",
  VideoName: "",
  VideoExtension: "",
  CollectionData: {
    Count: 0,
    Videos: []
  },
  progress: 0,

  videoPageNumber: 2,
  savedVideoPageNumber: 2,
  sharedByMeVideoPageNumber: 2,
  sharedWithMeVideoPageNumber: 2,

  collectionPageNumber: 2
};

export default (state = initialState, action) => {
  console.log(state)
  console.log(action)

  switch (action.type) {
    case actions.FETCH_TUTORIALS_SUCCEEDED: {
      return {
        ...state,
        tutorials: {
          Count: action.tutorials == "" ? 0 : action.tutorials.Count, Videos: action.tutorials == "" ? [] : action.tutorials.Videos
        },
        loaded: true,
      };
    }
    case actions.FETCH_TUTORIALS_LOADMORE_START:
      return {
        ...state,
        loaded: true,
      };
    case actions.FETCH_TUTORIALS_LOADMORE_SUCCEEDED:
      return {
        ...state,
        tutorials: { Count: action.tutorials.Count, Videos: [...state.tutorials.Videos, ...action.tutorials.Videos] },/// state.tutorials.concat(action.tutorials),
        lazyloader: true,
        videoPageNumber: state.videoPageNumber + 1
      };
    case actions.FETCH_ADMIN_TUTORIALS_LOADMORE_START:
      return {
        ...state,
        loaded: true,
      };
    case actions.FETCH_ADMIN_TUTORIALS_LOADMORE_SUCCEEDED:
      return {
        ...state,
        CollectionData: {
          Count: action.CollectionData.Count,
          Videos: [...state.CollectionData.Videos, ...action.CollectionData.Videos]
        },
        lazyloader: true,
        collectionPageNumber: state.collectionPageNumber + 1
      };
    case actions.FETCH_ADMIN_TUTORIALS_LOADMORE_FAILED:
      return {
        ...state,
        error: action.payload,
        loaded: false,
      };
    case actions.FETCH_ADMIN_TUTORIALS_LOADMORE_FINISHED:
      return {
        ...state,
        lazyloader: action.lazyloader,
      };
    case actions.FETCH_SAVED_TUTORIALS_SUCCEEDED:
      return {
        ...state,
        savedTutorials: {
          Count: action.savedTutorials == "" ? 0 : action.savedTutorials.Count, Videos: action.savedTutorials == "" ? [] : action.savedTutorials.Videos
        },
        loaded: true,
      };
    case actions.FETCH_SAVED_LOADMORE_TUTORIALS_SUCCEEDED:
      return {
        ...state,
        savedTutorials: { Count: action.savedTutorials.Count, Videos: [...state.savedTutorials.Videos, ...action.savedTutorials.Videos] },
        savedVideoLazyLoader: true,
        loaded: true,
        savedVideoPageNumber: state.savedVideoPageNumber + 1,
      };
    case actions.FETCH_SAVED_LOADMORE_TUTORIALS_FINISHED:
      return {
        ...state,
        savedVideoLazyLoader: false,
      };

    case actions.FILTER_TUTORIALS_START:
      return {
        ...state,
        loaded: true,
      };

    case actions.FILTER_TUTORIALS_SUCCEEDED:
      return {
        ...state,
        tutorials: action.tutorials,
      };

    case actions.SORT_TUTORIALS_START:
      return {
        ...state,
        loaded: true,
      };

    case actions.SORT_TUTORIALS_SUCCEEDED:
      return {
        ...state,
        tutorials: action.tutorials,
      };
    case actions.FETCH_TUTORIALS_LOADMORE_FAILED:
      return {
        ...state,
        error: action.payload,
        loaded: false,
      };
    case actions.FETCH_TUTORIALS_LOADMORE_FINISHED:
      return {
        ...state,
        lazyloader: action.lazyloader,
      };
    case actions.FETCH_TUTORIALS_DETAIL_START:
      return {
        ...state,
        tutorialsDetailLoaded: false
      };
    case actions.FETCH_TUTORIALS_DETAIL_SUCCESS:
      return {
        ...state,
        tutorialsDetail: action.tutorialsDetail,
        tutorialsDetailLoaded: true,
      };

    case actions.POST_TUTORIAL_VIDEO_SUCCEEDED:
      return {
        ...state,
        VideoData: action.payload.VideoData,
        VideoName: action.payload.VideoName,
        VideoExtension: action.payload.Extension,
      };

    case actions.VIDEO_UPLOAD_CHECK_SUCCEEDED:
      return {
        ...state,
        isVideoUploaded: true,
      };

    case actions.VIDEO_UPLOADED_SUCCESSFULLY:
      return {
        ...state,
        isVideoUploaded: false,
      };

    case actions.CREATE_TUTORIAL_DOCUMENT_SUCCEEDED:
      return {
        ...state,
        DocumentData: action.payload.DocumentData,
        DocumentName: action.payload.DocumentName,
        DocumentExtension: action.payload.Extension
      };

    case actions.FETCH_ADMIN_VIDEO_SUCCEEDED:
      return {
        ...state,
        CollectionData: {
          Count: action.CollectionData == "" ? 0 : action.CollectionData.Count,
          Videos: action.CollectionData == "" ? [] : action.CollectionData.Videos
        },
      };

    case actions.FETCH_SHAREDBYME_VIDEOS_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        sharedbymevideos: {
          Count: action.sharedbymevideos == "" ? 0 : action.sharedbymevideos.Count,
          Videos: action.sharedbymevideos == "" ? [] : action.sharedbymevideos.Videos
        },
      };

    case actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_SUCCEEDED:
      return {
        ...state,
        sharedbymevideos: { Count: action.sharedbymevideos.Count, Videos: [...state.sharedbymevideos.Videos, ...action.sharedbymevideos.Videos] },
        sharedBymeVideoLazyLoader: true,
        loaded: true,
        sharedByMeVideoPageNumber: state.sharedByMeVideoPageNumber + 1,
      };

    case actions.FETCH_SHAREDBYME_VIDEOS_LOADMORE_FINISHED:
      return {
        ...state,
        sharedBymeVideoLazyLoader: false,
      };
    case actions.FETCH_SHAREDWITHME_VIDEOS_SUCCEEDED:
      return {
        ...state,
        loaded: true,
        sharedwithmevideos: {
          Count: action.sharedwithmevideos == "" ? 0 : action.sharedwithmevideos.Count,
          Videos: action.sharedwithmevideos == "" ? [] : action.sharedwithmevideos.Videos
        },
      };
    case actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_SUCCEEDED:
      return {
        ...state,
        sharedwithmevideos: { Count: action.sharedwithmevideos.Count, Videos: [...state.sharedwithmevideos.Videos, ...action.sharedwithmevideos.Videos] },
        sharedWithMeVideoLazyLoader: true,
        loaded: true,
        sharedWithMeVideoPageNumber: state.sharedWithMeVideoPageNumber + 1,
      };

    case actions.FETCH_SHAREDWITHME_VIDEOS_LOADMORE_FINISHED:
      return {
        ...state,
        sharedWithMeVideoLazyLoader: false,
      };
    case actions.RESET_TUTORIALS_DETAILS_SUCCEEDED:
      return {
        ...state,
        tutorialsDetail: [],
      };
    default:
      return state;
  }
};
