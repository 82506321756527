import GenerateProfilePic from "../../components/Common/GenerateProfilePictureIntitals";
import actions from "../actions/actionTypes";

let initialState = {
  Quiz: { Count: 0, Quiz: [] },
  QuestionLevels: [],
  QuizPagenumber: 2,
  loaded: false,
  quizLazyLoader: true
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_QUIZ_SUCCEEDED: {
      console.log(action);
      console.log(state);
      return {
        ...state,
        Quiz: {
          Count: action.quiz === "" ? 0 : action.quiz.Count,
          Quiz: action.quiz === "" ? [] : action.quiz.Quiz
        },
        loaded: true,
      };
    }
    case actions.FETCH_QUIZ_LOADMORE_SUCCEEDED:
      return {
        ...state,
        Quiz: { Count: action.quiz.Count, Quiz: [...state.Quiz.Quiz, ...action.quiz.Quiz] },
        quizLazyLoader: true,
        loaded: true,
        QuizPagenumber: state.QuizPagenumber + 1,
      };

    case actions.FETCH_QUIZ_LOADMORE_FINISHED:
      return {
        ...state,
        quizLazyLoader: false,
      };
    case actions.FETCH_QUESTION_LEVELS_SUCCEEDED:
      return {
        ...state,
        QuestionLevels: action.levels,
      };
    default:
      return state;
  }
}

export default quizReducer;