
import React from "react";
import SortLabel from "../../components/TranslatedComponents/SortLabel";
import SortByLabel from "../../components/TranslatedComponents/SortByLabel";
import CategoryLabel from "../../components/TranslatedComponents/CategoryLabel";
import BookmarkLabel from "../../components/TranslatedComponents/BookmarkLabel";
import SharedWithYouLabel from "../../components/TranslatedComponents/SharedWithYouLabel";
import SharedByYouLabel from "../../components/TranslatedComponents/SharedByYouLabel";
import i18n from "../../utils/i18n";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../store/configureStore";
import { sorting } from "../../components/Common/Constants";
function BrowseHeader(props) {
  const categories = useSelector(() => store.getState().category.categories);
  const isLeftToRight = document.body.dir === "ltr";
  return (
    <div class="search-nav-bar-brows browse">
      <section id="search-for-brows" className="lms-flex-align-center m-40">
        <div className="lms-search-width">
          <div className="search-img lms-flex-align-center">
            <input
              type="search"
              id="grid-search"
              placeholder={i18n.t("Search_PlaceHolder_Label")}
              onChange={(evt) => props.filterData(evt.target.value)}
            />
            <img src="../assets/search.png" alt="lms-logo" />
          </div>
        </div>
        <div className="lms-videodesktop-tabs">
          <div className="search-right lms-flex">
            <NavLink to={"/browse"}>
              <div id={`${isLeftToRight ? "right-icon-right" : "right-icon-left"}`} className={props.browseType == "SavedByMe" ? "right-icon lms-flex-align-center active-tab" : "right-icon lms-flex-align-center"}>
                <p>
                  <BookmarkLabel />
                </p>
              </div>
            </NavLink>
            <NavLink to={"/browsesharedwithyou"}>
              <div id={`${isLeftToRight ? "right-icon-right" : "right-icon-left"}`} className={props.browseType == "SharedWithMe" ? "right-icon lms-flex-align-center active-tab" : "right-icon lms-flex-align-center"}>
                <p>
                  <SharedWithYouLabel />
                </p>
              </div>
            </NavLink>
            <NavLink to={"/browsesharedbyyou"}>
              <div id={`${isLeftToRight ? "right-icon-right" : "right-icon-left"}`} className={props.browseType == "SharedByMe" ? "right-icon lms-flex-align-center active-tab" : "right-icon lms-flex-align-center"}>
                <p>
                  <SharedByYouLabel />
                </p>
              </div>
            </NavLink>
            <div className="filter-sort right-icon d-flex align-items-center dropdown">
              <svg
                className="col-2"
                width="20px"
                height="14px"
                viewBox="0 0 20 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Visual-Design-2"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Dashboard"
                    transform="translate(-1715.000000, -328.000000)"
                    className="svg-for-menu"
                    fill-rule="nonzero"
                  >
                    <g
                      id="Search-&amp;-filters-2"
                      transform="translate(195.000000, 298.000000)"
                    >
                      <g
                        id="Group"
                        transform="translate(1520.000000, 28.000000)"
                      >
                        <g
                          id="noun_Sort_2005356"
                          transform="translate(0.000000, 2.000000)"
                        >
                          <path
                            d="M5.71428571,11.2 C6.50326393,11.2 7.14285714,11.8268014 7.14285714,12.6 C7.14285714,13.3731986 6.50326393,14 5.71428571,14 L1.42857143,14 C0.639593215,14 0,13.3731986 0,12.6 C0,11.8268014 0.639593215,11.2 1.42857143,11.2 L5.71428571,11.2 Z M14.2857143,5.6 C15.0746925,5.6 15.7142857,6.22680135 15.7142857,7 C15.7142857,7.77319865 15.0746925,8.4 14.2857143,8.4 L1.42857143,8.4 C0.639593215,8.4 0,7.77319865 0,7 C0,6.22680135 0.639593215,5.6 1.42857143,5.6 L14.2857143,5.6 Z M18.5714286,0 C19.3604068,0 20,0.62680135 20,1.4 C20,2.17319865 19.3604068,2.8 18.5714286,2.8 L1.42857143,2.8 C0.639593215,2.8 0,2.17319865 0,1.4 C0,0.62680135 0.639593215,0 1.42857143,0 L18.5714286,0 Z"
                            id="Combined-Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <p className="col-6">
                <SortLabel />
              </p>
              <div className={`dropdown-content lang-dpdw-menu ${document.body.dir == 'rtl' ? 'rtl-drop-down' : 'ltr-drop-down'}`}>
                <div className="d-grid form-field">
                  <label for="sort">
                    <SortByLabel />
                  </label>
                  <select id="filter" onChange={(evt) => props.changeSort(evt.target.value)}>
                    {sorting.map((item, index) => (
                      <option key={item.value} selected={index === 1} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="d-grid form-field">
                  <label for="sort">
                    <CategoryLabel />
                  </label>
                  <select id="filter" onChange={(evt) => props.change(evt.target.value)}>
                    <option key={'all'} value={''}>
                      - ALL -
                    </option>
                    {
                      categories.map(i => (
                        <option value={i.CategoryName}>{i.CategoryName}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default BrowseHeader
