import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import API from "../../utils/API";
import { Checkbox, CircularProgress, Tooltip } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { fontSize, fontWeight } from "@mui/system";
import { quizStatusCompleted, quizStatusFail, quizStatusInProgress, quizStatusPass, quizStatusStarted } from "./Constants";
import { multipleChoice, singleChoice } from "../../modules/Admin/Common/Constants";

export default function UserQuiz(props) {
    const [isSubmission, setSubmissionActive] = React.useState(true);
    const [finalScoreCard, setFinalScoreCard] = React.useState(false);
    const [quiz, setQuiz] = React.useState({});

    const [quizIndex, setQuizIndex] = React.useState(0);

    const [localLoader, setLocalLoader] = React.useState(true);

    const [submitLoader, setSubmitloader] = React.useState(true);

    const [metaData, setMetaData] = React.useState({});

    const [choiceId, setChoiceId] = React.useState([]);

    const [isNextButtonNotActive, setisNextButtonNotActive] = React.useState(false);

    const [questionSet, setQuestionSet] = React.useState([]);
    const [questionNumber, setQuestionNumber] = React.useState(1);
    const [questionPercent, setQuestionPercent] = React.useState(0);
    const [scoreCard, setScoreCard] = React.useState({});

    const [screens, setScreens] = React.useState({
        startQuiz: true,
        question: false,
        scoreCard: false
    });



    const params = useParams();
    const navigate = useNavigate()
    const { t } = useTranslation()

    const Skip = t("Skip")
    const Next = t("Next")
    const Retake_Quiz = t("Retake_Quiz")
    const Your_Score = t("Your_Score")
    const Quiz_completed = t("Quiz_completed")
    const Correct = t("Correct")
    const Wrong = t("Wrong")
    const Points_Earned = t("Points_Earned")
    const Quiz_Score_Card_Notification = t("Quiz_Score_Card_Notification")
    const Dashboard_Header_Label = t("Dashboard_Header_Label")
    const Question = t("Question")
    const Take_Assessment = t("Take_Assessment")


    console.log(props)
    const playlistId = params.id
    const videoIndex = params.videoId

    const handleChoice = (id) => {
        var list = choiceId;
        if (!list.includes(parseInt(id))) {
            list.push(parseInt(id));
        }
        else {
            list.pop(parseInt(id))
        }
        setChoiceId(list);
    }

    const handleRetake = () => {
        setLocalLoader(true)
        API.retakeQuiz({
            userId: props.userId,
            quizId: props.quiz.Id,
            playlistId: playlistId
        }).then(res => {
            if (res.status === 200) {
                API.createQuestionSet({
                    quizId: props.quiz.Id,
                    userId: props.userId
                }).then(res => {
                    if (res.status === 200) {
                        API.startQuiz({
                            quizId: props.quiz.Id,
                            userId: props.userId,
                            playlistId: playlistId
                        }).then(res => res.status === 200 && API.getQuestionSet({
                            quizId: props.quiz.Id,
                            userId: props.userId
                        })).then(res => {
                            if (res.status === 200) {
                                setQuestionSet(res.data)
                                setScreens({
                                    startQuiz: false,
                                    question: true,
                                    scoreCard: false
                                })
                                setLocalLoader(false)
                                setQuestionNumber(1)
                                setQuestionPercent(0)
                            }
                        })
                    }
                })
            }
        })
    }

    const handleSkip = () => {
        if ((parseInt(props.courseLength) - 1) != videoIndex) {
            navigate("/course/" + playlistId + "/" + (parseInt(videoIndex) + 1))
        }
        else {
            setFinalScoreCard(true)
        }
    }

    React.useEffect(() => {
        setScoreCard({})
        setLocalLoader(true);
        API.getUserScore({
            userId: props.userId,
            quizId: props.quiz.Id,
            playlistId: playlistId
        }).then(res => {
            if (res.status === 200) {
                var data = res.data
                evaluateSuggestions(data.LevelScore)
                setScoreCard({
                    Attempt: data.Attempt,
                    LevelScore: data.LevelScore,
                    PassingPercentage: data.PassingPercentage,
                    Percentage: isNaN(data.Percentage) || data.Percentage == null ? 0 :data.Percentage,
                    Score: data.Score,
                    ScoreId: data.ScoreId,
                    TotalScore: data.TotalScore,
                    Retake: data.RetakeLeft > 0,
                    RetakeLeft: data.RetakeLeft,
                    Suggestion: evaluateSuggestions(data.LevelScore),
                    Status: data.Percentage >= data.PassingPercentage ? quizStatusPass : quizStatusFail
                })
                setScreens({
                    startQuiz: false,
                    question: false,
                    scoreCard: true
                })
            }
            else {
                setScreens({
                    startQuiz: true,
                    question: false,
                    scoreCard: false
                })
            }
            setLocalLoader(false);
        })
    }, [window.location.pathname])

    const evaluateSuggestions = (levels) => {
        const arr = []
        var suggestion = {}
        levels.map(a => {
            if (a.SuggestionId === suggestion.Id) {
                arr.filter(j => j.Id === a.SuggestionId).in
                const objectToReplace = arr.find(arrayItem => arrayItem.Id === a.SuggestionId);
                const newObj = {
                    ...objectToReplace,
                    Levels: objectToReplace.Levels.concat(", " + props.levels.find(j => j.Id === a.LevelId).Name),
                }
                Object.assign(objectToReplace, newObj);

                if (arr.indexOf(objectToReplace) === -1) {
                    arr.push(newObj)
                }
                else (
                    arr[arr.indexOf(objectToReplace)] = newObj
                )
            }
            else {
                suggestion = {
                    Id: a.SuggestionId,
                    Levels: props.levels.find(j => j.Id === a.LevelId).Name,
                    Title: a.Suggestions
                }
                arr.push(suggestion)
            }
        })

        return arr
    }

    const goToNextQuestion = (questionId) => {
        setLocalLoader(true);
        if (choiceId.length > 0) {
            API.updateScoreOnAnswer(
                {
                    PlaylistId: playlistId,
                    UserId: props.userId,
                    QuizId: props.quiz.Id,
                    QuestionId: questionId,
                    ChoiceId: choiceId,
                    IsSubmitQuiz: getQuestionCount() === questionNumber
                }
            ).then(res => {
                if (questionNumber === getQuestionCount()) {
                    API.getUserScore({
                        userId: props.userId,
                        quizId: props.quiz.Id,
                        playlistId: playlistId
                    }).then(res => {
                        if (res.status === 200) {
                            var data = res.data
                            setScoreCard({
                                Attempt: data.Attempt,
                                LevelScore: data.LevelScore,
                                PassingPercentage: data.PassingPercentage,
                                Percentage: isNaN(data.Percentage) || data.Percentage == null ? 0 :data.Percentage,
                                Score: data.Score,
                                ScoreId: data.ScoreId,
                                TotalScore: data.TotalScore,
                                Retake: data.RetakeLeft > 0,
                                RetakeLeft: data.RetakeLeft,
                                Suggestion: evaluateSuggestions(data.LevelScore),
                                Status: data.Percentage >= data.PassingPercentage ? quizStatusPass : quizStatusFail
                            })
                            setScreens({
                                startQuiz: false,
                                question: false,
                                scoreCard: true
                            })
                        }
                    }).then(() => {
                        setLocalLoader(false);
                    })
                }
                else {
                    setQuestionNumber((prevState) => prevState + 1);
                    setLocalLoader(false);
                }
                setChoiceId([]);
                var per = (questionNumber / getQuestionCount()) * 100
                setQuestionPercent(per)
            })
        }
        else {
            if (questionNumber === getQuestionCount()) {
                API.getUserScore({
                    userId: props.userId,
                    quizId: props.quiz.Id,
                    playlistId: playlistId
                }).then(res => {
                    if (res.status === 200) {
                        var data = res.data
                        setScoreCard({
                            Attempt: data.Attempt,
                            LevelScore: data.LevelScore,
                            PassingPercentage: data.PassingPercentage,
                            Percentage: isNaN(data.Percentage) || data.Percentage == null ? 0 :data.Percentage,
                            Score: data.Score,
                            ScoreId: data.ScoreId,
                            TotalScore: data.TotalScore,
                            Retake: data.RetakeLeft > 0,
                            RetakeLeft: data.RetakeLeft,
                            Suggestion: evaluateSuggestions(data.LevelScore),
                            Status: data.Percentage >= data.PassingPercentage ? quizStatusPass : quizStatusFail
                        })
                        setScreens({
                            startQuiz: false,
                            question: false,
                            scoreCard: true
                        })
                    }
                }).then(() => {
                    setLocalLoader(false);
                })
            }
            else {
                setQuestionNumber((prevState) => prevState + 1);
                setLocalLoader(false);
            }
            setChoiceId([]);
        }

    }

    const handleStartQuiz = () => {
        setLocalLoader(true)
        API.getLastAttemptScore({
            quizId: props.quiz.Id,
            userId: props.userId,
            playlistId: playlistId
        }).then(res => {
            if (res.status === 204) {
                API.createQuestionSet({
                    quizId: props.quiz.Id,
                    userId: props.userId
                }).then(res => {
                    if (res.status === 200) {
                        API.startQuiz({
                            quizId: props.quiz.Id,
                            userId: props.userId,
                            playlistId: playlistId
                        }).then(res => res.status === 200 && API.getQuestionSet({
                            quizId: props.quiz.Id,
                            userId: props.userId
                        })).then(res => {
                            if (res.status === 200) {
                                setQuestionSet(res.data)
                                setScreens({
                                    startQuiz: false,
                                    question: true,
                                    scoreCard: false
                                })
                                setLocalLoader(false)
                                setQuestionNumber(1)
                                setQuestionPercent(0)
                            }
                        })
                    }
                })
            }
            if (res.status === 200) {
                if (res.data.Status === quizStatusCompleted) {
                    API.retakeQuiz({
                        quizId: props.quiz.Id,
                        userId: props.userId,
                        playlistId: playlistId
                    }).then(res => {
                        if (res.status === 200)
                            API.createQuestionSet({
                                quizId: props.quiz.Id,
                                userId: props.userId
                            }).then(res => {
                                if (res.status === 200) {
                                    API.getQuestionSet({
                                        quizId: props.quiz.Id,
                                        userId: props.userId
                                    }).then(res => {
                                        if (res.status === 200) {
                                            setQuestionSet(res.data)
                                            setScreens({
                                                startQuiz: false,
                                                question: true,
                                                scoreCard: false
                                            })
                                            setLocalLoader(false)
                                            setQuestionNumber(1)
                                            setQuestionPercent(0)
                                        }
                                    })
                                }
                            })
                    })
                }
                if (res.data.Status === quizStatusInProgress || res.data.Status === quizStatusStarted) {
                    API.getQuestionSet({
                        quizId: props.quiz.Id,
                        userId: props.userId
                    }).then(res => {
                        if (res.status === 200) {
                            setQuestionSet(res.data)
                            setScreens({
                                startQuiz: false,
                                question: true,
                                scoreCard: false
                            })
                            setLocalLoader(false)
                            setQuestionNumber(1)
                            setQuestionPercent(0)
                        }
                    })
                }
            }
        })
    }


    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: "0.88vw",
        borderRadius: 0,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "#FF8DAF",
            '&::after': {
                content: '"3"',
                position: 'absolute',
                top: '50%',
                left: '96%',
                transform: 'translate(-50%, -50%)',
                color: 'black',
                zIndex: 1,
                fontSize: "0.72vw",
                fontWeight: "600",
                '@media (max-width: 991px)': {
                    fontSize: "12px",
                },
            },

        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 0,
            backgroundColor: "#8BEEC4",
            '&::after': {
                content: '"8"',
                position: 'absolute',
                top: '50%',
                left: '96%',
                transform: 'translate(-50%, -50%)',
                color: 'black',
                zIndex: 1,
                fontSize: "0.72vw",
                fontWeight: "600",
                '@media (max-width: 991px)': {
                    fontSize: "12px",
                },
            },
            '@media (max-width: 991px)': {
                fontSize: "12px",
            },
        },
        '@media (max-width: 991px)': {
            height: "14px",
        },
    }));

    const BorderLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
        height: 6,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const getQuestionCount = () => {
        return (questionSet.find(a => a.Quiz.Id === props.quiz.Id).Questions.length)
    }

    const getQuestionList = () => {
        return (questionSet.find(a => a.Quiz.Id === props.quiz.Id).Questions)
    }

    return !localLoader ? (
        <div className="user-quiz-container" style={{ borderRadius: 20, marginBottom: 35 }}>

            {
                screens.startQuiz &&
                <div className="question-container">
                    <div style={{ display: 'flex', height: '43vh', justifyContent: 'center', alignItems: 'center' }}>
                        <button className="primary-button sec-btn" onClick={handleStartQuiz}>Start Quiz</button>
                    </div>
                </div>
            }

            {
                screens.question &&
                <div className="question-container">
                    <div>
                        <div className="user-quiz-header">
                            <div className="d-flex align-items-center mb-3 justify-content-between"><h3>{Take_Assessment}</h3>
                                <h2>Question {questionNumber}/{getQuestionCount()}</h2></div>
                            <BorderLinearProgressBar variant="determinate" value={questionPercent} />
                        </div>
                        <div className="d-flex align-items-center question-con">
                            <FormControl className="quiz-question-con">
                                <FormLabel id="question-title"><h2>{getQuestionList()[questionNumber - 1].Question} ?</h2></FormLabel>
                                {
                                    getQuestionList()[questionNumber - 1].Type === singleChoice &&
                                    <RadioGroup
                                        aria-labelledby="question-title"
                                        name="radio-buttons-group"
                                        onChange={(e) => handleChoice(e.target.value)}
                                    >
                                        {getQuestionList()[questionNumber - 1].Choice.map(a => {
                                            return (
                                                <FormControlLabel value={a.ChoiceId} control={<Radio />} label={a.Choice} />
                                            )
                                        })}
                                    </RadioGroup>
                                }

                                {
                                    getQuestionList()[questionNumber - 1].Type === multipleChoice &&
                                    getQuestionList()[questionNumber - 1].Choice.map(a => {
                                        return (
                                            <FormControlLabel value={a.ChoiceId} control={<Checkbox />} label={a.Choice} onChange={(e) => handleChoice(e.target.value)} />
                                        )
                                    })
                                }
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end user-quiz-btn-group">
                        <button className="primary-button quiz-cancel-btn">Cancel</button>
                        <button className="primary-button sec-btn" disabled={isNextButtonNotActive} onClick={() => goToNextQuestion(getQuestionList()[questionNumber - 1].QuestionId)}>Next</button>
                    </div>
                </div>
            }

            {
                screens.scoreCard &&
                <div className="submission-con score-card-one"  >
                    {
                        scoreCard.Status === quizStatusFail &&
                        <>
                            <div className="success-icon"><img src="../assets/fail-icon.png" alt="fail-icon" /></div>
                            <div><h1>You have failed the Quiz</h1></div>
                        </>
                    }
                    {
                        scoreCard.Status === quizStatusPass &&
                        <>
                            <div className="success-icon"><img src="../assets/success-icon.png" alt="Success-icon" /></div>
                            <div><h1>You have passed the Quiz</h1></div>
                        </>
                    }

                    <div className="score-container score-container-1">
                        <div className="scrore-percentage">
                            <p>Score Percentage:</p>
                            <h3 >{parseInt(scoreCard.Percentage)}%</h3>
                        </div>
                    </div>
                    <div className="result-with-progress" >
                        {
                            props.levels.map((level, idx) => {
                                var levelScoreLength = scoreCard.LevelScore.filter(a => a.LevelId === level.Id).length;
                                var levelScore = levelScoreLength > 0 ? scoreCard.LevelScore.find(a => a.LevelId === level.Id) : 0
                                return (
                                    <div className={`progrees-div ${parseInt(props.levels.length / 2) === 1 && 'mx-2'}`}>
                                        <h2>{level.Name} (Total: {levelScoreLength > 0 ? levelScore.TotalQuestions : 0})</h2>
                                        <div class=" d-flex align-items-center progress score-card-progress">
                                            {
                                                scoreCard.LevelScore.filter(a => a.LevelId == level.Id).map(a => {
                                                    var correct = (a.CorrectQuestions / a.TotalQuestions)
                                                    var inCorrect = (a.InCorrectQuestions / a.TotalQuestions)
                                                    var notAttempted = a.TotalQuestions - (a.CorrectQuestions + a.InCorrectQuestions)
                                                    return (
                                                        <>
                                                            <div class="progress-bar success" role="progressbar" style={{ width: `${correct * 100}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax={100}>{a.CorrectQuestions}</div>
                                                            <div class="progress-bar error" role="progressbar" style={{ width: `${inCorrect * 100}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax={100}>{a.InCorrectQuestions}</div>
                                                            <div class="progress-bar unattempt" role="progressbar" style={{ width: `${notAttempted * 100}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax={100}>{notAttempted}</div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* {
                        scoreCard.Status === quizStatusPass &&
                        <div className="score-container result-alert">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 18.3334C15.1023 18.3334 18.8333 14.6024 18.8333 10C18.8333 5.39765 15.1023 1.66669 10.5 1.66669C5.89759 1.66669 2.16663 5.39765 2.16663 10C2.16663 14.6024 5.89759 18.3334 10.5 18.3334Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.5 13.3333V10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.5 6.66669H10.5083" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p>Passed: Need to Focus more on Basic concepts. </p>
                        </div>
                    }
                    {
                        scoreCard.Status === quizStatusFail &&
                        <div className="score-container result-alert res-failed">
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 18.3334C15.1023 18.3334 18.8333 14.6024 18.8333 10C18.8333 5.39765 15.1023 1.66669 10.5 1.66669C5.89759 1.66669 2.16663 5.39765 2.16663 10C2.16663 14.6024 5.89759 18.3334 10.5 18.3334Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.5 13.3333V10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.5 6.66669H10.5083" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p>Failed: You require more {scoreCard.TotalScore - scoreCard.Score} points to pass, focus more on  Concepts</p>
                        </div>
                    } */}
                    {console.log(scoreCard)}
                    <div className="score-card-suggestions">
                        <ol>
                            {
                                scoreCard.Suggestion.map((a, idx) => {
                                  return <li>{a.Title.replaceAll('{0}', a.Levels)}</li>
                                })
                            }
                            <li>Focus on attempting challenging questions and seek help when needed. Consistent practice will enhance your performance.</li>
                        </ol>
                    </div>
                    <div className="score-container score-container-1">
                        {
                            scoreCard.Status === quizStatusFail &&
                            // <p>You require <strong>{scoreCard.TotalScore}</strong> to <strong>pass</strong> in the quiz, click retake to give the quiz again.</p>
                            <p>You have Failed the quiz, <strong>Retake Quiz</strong>. You have <strong>{scoreCard.RetakeLeft} chances</strong> more to complete Quiz.</p>
                        }
                        {
                            scoreCard.Status === quizStatusPass &&
                            <p>You have passed the quiz, click <strong>continue</strong> to move to the next video. </p>
                        }
                    </div>
                    <div className="d-flex align-items-center justify-content-center result-btn-group">
                        <Tooltip title={!scoreCard.Retake ? "No retake left" : ""}>
                            <button className="primary-button quiz-cancel-btn-1" disabled={!scoreCard.Retake} onClick={handleRetake}>Retake</button>
                        </Tooltip>
                        <button className="primary-button sec-btn" onClick={handleSkip}>Continue</button>
                    </div>

                </div>

            }
        </div >)
        :
        (
            <div className="user-quiz-container" >
                <div className="d-flex align-items-center justify-content-center">
                    <div className="gif-quiz">
                        <CircularProgress style={{ width: 40 }} />
                    </div>
                </div>
            </div>
        )
}