import React from 'react'

export default function Spinner() {
    return (
        <div className="gif">
            <div className="spinner-border">
                <img src="../assets/loading.gif" alt=""></img>
            </div>
        </div>
    )
}
