import { FusionAuthConfig } from '@fusionauth/react-sdk';


export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENTID,
        authority: process.env.REACT_APP_AUTH_CLOUD_INSTANCE + "/" + process.env.REACT_APP_AUTH_TENANT_ID,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: [`api://${process.env.REACT_APP_AUTH_CLIENTID}/.default`]
};