import React from 'react';
import EditProfile from "./EditProfile"
import { NavLink } from "react-router-dom";

function EditUserProfile (props)
{
  const {handleEditBtn } = props;
  let proPic = props.props.profile.ProfileImageUrl != null ? props.props.profile.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? props.props.profile.ProfileImageUrl : sessionStorage.getItem('profilePic') : sessionStorage.getItem('profilePic');
  return(
    <div>
  <div className="edit-user-profile-con">
    <div className="edit-user-profile">

        <div className="profile-user-details">
        
          <div className="lms-flex ">
            <div className="userprofile lms-flex">
              <div className="edit-profile-img">
                <img src={proPic} alt="lms-logo" />     
              </div>
              <div className="profile-para lms-l-c2">
              <h1 className="profile-heading sec-pro-heading">{props.props.profile.Name}</h1>    
              <p className="p-0 profile-mailid">
              {props.props.profile.Email}
              </p>          
            </div>
            </div>
            <div className="user-close-icon">
                <img src="../assets/close-black.png" alt="lms-logo" onClick={handleEditBtn} className="btn-edit-close"/>
              </div> 
          </div>
        
        </div>
        <div className="edit-user-details">
            <div className="">
                <EditProfile props={props}></EditProfile>
            </div>
        </div>
        </div>  
        </div>
        </div>
  );
}
 
export default EditUserProfile;