import actions from "./actionTypes";

const getProfileData = (payload) => {
  return {
    type: actions.GET_PROFILE_DATA_START,
    payload
  };
};

const setProfileData = (profile) => {
  return {
    type: actions.GET_PROFILE_DATA_SUCCEEDED,
    profile
  };
}

const setProfileDataError = (error) => {
  return {
    type: actions.GET_PROFILE_DATA_FAILED,
    error
  };
}

export { getProfileData, setProfileData, setProfileDataError }