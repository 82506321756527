import React, { Component } from "react";
import TutorialsList from "../components/TutorialsList/TutorialsList";
import CoursesList from "../components/CoursesList/CoursesList";
import { connect } from "react-redux";
import PlaceholderLoader from "../components/Common/PlaceholderLoader";
import { bindActionCreators } from "redux";
import {
  fetchTutorials,
  fetchTutorialsLoadmore,
  filterTutorials,
  sortTutorials,
  searchTutorials,
} from "../store/actions/tutorials";
import {
  fetchCourseReport,
  fetchCourses,
  fetchCoursesLoadmore
} from "../store/actions/courses";
import { getProfileData } from '../store/actions/profileDataAction'
import { NavLink } from "react-router-dom";

import { BlueBarTranslated } from "../components/Common/BlueBar"

import AllLabel from "../components/TranslatedComponents/AllLabel";
import VideoLabel from "../components/TranslatedComponents/VideoLabel";
import CoursesLabel from "../components/TranslatedComponents/CoursesLabel";
import SortLabel from "../components/TranslatedComponents/SortLabel";
import SortByLabel from "../components/TranslatedComponents/SortByLabel"
import CategoryLabel from "../components/TranslatedComponents/CategoryLabel"
import VideosLabel from "../components/TranslatedComponents/VideoLabel";
import i18n from "../utils/i18n";
import API from "../utils/API";
import Videos from "../components/VideosList/Videos";
import { fetchCategories, fetchPlaylistCategories } from "../store/actions/CategoryAction";
import Cookies from "js-cookie";
import { blueBarCourseAction } from "../store/actions/BlueBarAction";
import LoadingScreen from "../components/Common/LoadingScreen";
import { userData } from "../components/Common/Constants";
import { getAllUsers } from "../store/actions/AllUsersAction";

export class App extends Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
    this.state = {
      search: "",
      videoFilterData: [],
      courseFilterData: [],
      hasError: false,
      options: [
        {
          name: "Entertainment",
          value: "Entertainment",
        },
        {
          name: "News",
          value: "News",
        },
        {
          name: "Media",
          value: "Media",
        },
        {
          name: "Science & Technology",
          value: "Science & Technology",
        },
      ],
      sort: [
        {
          name: "Ascending",
          value: "asc",
        },
        {
          name: "Decending",
          value: "desc",
        },
      ],
    };
    //this.handleScroll = this.handleScroll.bind(this);
  }


  componentDidMount() {
    const {user, fetchActionCreator,fetchAllUserAction, getProfileData, fetchCourseAction, getCategories, getPlaylistCategories, profile, getCourseReport, bluebarCourseAction } = this.props;
    let userInfo ;
    if(Cookies.get(userData))
    {
      userInfo = JSON.parse(Cookies.get('user'))
      // window.heap.identify(userInfo.sub);
      getProfileData(userInfo.sub)
      bluebarCourseAction(userInfo.sub)
    }
    if (this.props.videoPageNumber === 2) {
      fetchActionCreator();
    }
    if (this.props.coursePageNumber === 2) {
      fetchCourseAction();
    }
    fetchAllUserAction({
      PageNumber: 1,
      PageSize: 20
    })
    getCourseReport();
    getCategories()
    getPlaylistCategories()
  }

  handleChange = async (event) => {
    this.setState({ value: event.target.value });
    await API.fetchTutorialsFilter({
      filterField: "CategoryName",
      filterText: event.target.value.trim()
    }).then(res => res.status === 200 && this.setState({ videoFilterData: res.data }))

    await API.fetchCoursesFilter({
      FilterField: "PlaylistCategoryName",
      FilterText: event.target.value.trim()
    }).then(res => res.status === 200 && this.setState({ courseFilterData: res.data }))
  };

  handleChangesort = async (event) => {
    this.setState({ value: event.target.value });
    await API.sortTutorials(event.target.value.trim()).then(res => res.status === 200 && this.setState({ videoFilterData: res.data }))

    await API.fetchCoursesFilter({
      SortField: "CreatedDate",
      SortType: event.target.value.trim()
    }).then(res => res.status === 200 && this.setState({ courseFilterData: res.data }))
  };

  getFilterValue = (e) => {
    const search = e.target.value;
    if (e.target.value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/)) {
      this.setState({ hasError: false });
    } else {
      this.setState({ hasError: true });
    }
    if (search != null) {
      this.setState({ search }, async () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout( async() => {
          await API.fetchTutorialsFilter({
            filterField: "Name",
            filterText: search.trim()
          }).then(res => res.status === 200 && this.setState({ videoFilterData: res.data }))
          await API.fetchCoursesFilter({
            FilterField: "Title",
            FilterText: search.trim()
          }).then(res => res.status === 200 && this.setState({ courseFilterData: res.data }))
        }, 500);
      });
    }
  };

  handleScroll = (event) => {
    const element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      const { loadMoreActionCreator, featchCoursesLoadMoreAction } = this.props;
      loadMoreActionCreator(this.props.videoPageNumber);
      featchCoursesLoadMoreAction(this.props.coursePageNumber);
      console.log(this.props.Videos);
    }
  };

  render() {
    const { tutorials, loaded, videoLazyloader, courseLazyloader, courses } = this.props;

    this.getFilterData = () => {
      return tutorials?.filter(
        (item) =>
          item?.Title?.toLowerCase().match(this.state.search.toLowerCase()) ||
          item?.CategoryName?.toLowerCase().match(
            this.state.search.toLowerCase()
          ) ||
          item?.Description?.toLowerCase().match(this.state.search.toLowerCase())
      );
    };
    this.getCourseFilterData = () => {
      // console.log('coursessss',courses[0].Title.toLowerCase().match(this.state.search.toLocaleLowerCase()))
      return courses.filter(
        (item) =>
          item.Name.toLowerCase().match(this.state.search.toLowerCase()) ||
          item.CategoryName.toLowerCase().match(
            this.state.search.toLowerCase()
          ) ||
          item.Description.toLowerCase().match(this.state.search.toLowerCase())
      );
    };

    return loaded ? (
    <Videos/>
    ) : (
      <LoadingScreen/>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorials: state.tutorials.tutorials,
  courses: state.courses.courses,
  loaded: state.tutorials.loaded,
  videoLazyloader: state.tutorials.lazyloader,
  courseLazyloader: state.courses.lazyloader,
  profile: state.profileData,
  user: state.user.user,
  videoPageNumber: state.tutorials.videoPageNumber,
  coursePageNumber: state.courses.coursePageNumber,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchActionCreator: fetchTutorials,
      fetchCourseAction: fetchCourses,
      loadMoreActionCreator: fetchTutorialsLoadmore,
      featchCoursesLoadMoreAction: fetchCoursesLoadmore,
      filterAction: filterTutorials,
      sortAction: sortTutorials,
      searchAction: searchTutorials,
      getProfileData: getProfileData,
      getCategories: fetchCategories,
      getPlaylistCategories: fetchPlaylistCategories,
      getCourseReport: fetchCourseReport,
      bluebarCourseAction: blueBarCourseAction,
      fetchAllUserAction: getAllUsers,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
