import actions from "./actionTypes";

const getUserDetails = (payload) => {
  return {
    type: actions.GET_USER_DETAILS_START,
    payload
  };
};

const setUserDetails = (profile) => {
  return {
    type: actions.GET_USER_DETAILS_SUCCEEDED,
    profile
  };
}

const setUserDetailsError = (error) => {
  return {
    type: actions.GET_USER_DETAILS_FAILED,
    error
  };
}

const signOutUser = () => {
  return {
    type: actions.SIGNOUT_USER
  };
}

export { getUserDetails, setUserDetails, setUserDetailsError, signOutUser }